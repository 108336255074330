import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../../utils/url';
import request from '../../../../../utils/request';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

export const fetchPerDiemOverrides = createAsyncThunk(
  'dispatch/perDiemOverrides/fetchAll',
  async (payload: string[]) => {
    const uuids = payload.join(',');

    const options = {
      method: 'POST'
    };

    const url = api(`/per-diem-due-date-calculation?uuids=${uuids}`);

    try {
      const response = (await request(url, options)) as { data: any[] };

      return response.data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Per Diem Due Date Override" />);
    }
  }
);

export const perDiemOverridesAdapter = createEntityAdapter<any>({
  selectId: (item: any) => item.uuid
});

const perDiemOverridesSlice = createSlice({
  name: 'dispatch/perDiemOverrides',
  initialState: perDiemOverridesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPerDiemOverrides.fulfilled, (state, action) => {
      const data = action.payload || [];

      perDiemOverridesAdapter.setAll(state, data);
    });
  }
});

export const selectPerDiemOverrides = (state: any) => state.dispatch.perDiemOverrides;
export const { selectAll: selectAllPerDiemOverrides, selectById: selectPerDiemOverrideById } =
  perDiemOverridesAdapter.getSelectors<any>(selectPerDiemOverrides);

export default perDiemOverridesSlice.reducer;
