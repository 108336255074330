import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { convertToDBGlobalCustomerUuid, IPerDiemFreeDayRule } from '../models/IPerDiemFreeDayRules';
import { api } from '../../../../../../../utils/url';
import request from '../../../../../../../utils/request';
import { raiseToast } from '../../../../../../../components/Toaster';
import SystemToast from '../../../../../../../components/SystemToast';

export const fetchPerDiemFreeDayRules = createAsyncThunk('settings/perDiemFreeDays/fetchAll', async () => {
  const url = api(`/per-diem-due-date-free-day-rules`);

  try {
    const result = (await request(url)) as { data: IPerDiemFreeDayRule[] };
    const items = result.data;

    return items;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Per Diem Rules" />);
  }
});

export const updatePerDiemFreeDayRule = createAsyncThunk(
  'settings/perDiemFreeDays/update',
  async (payload: IPerDiemFreeDayRule, thunkAPI) => {
    // any because of a bad prop reference below (shipperUuid). Def a bug. */
    const rule: any = convertToDBGlobalCustomerUuid(payload);
    const data = [
      {
        uuid: rule.uuid,
        numOfFreeDays: rule.numOfFreeDays,
        steamShippingLineAccountUuid: rule.shipperUuid,
        customerUuid: rule.customerUuid,
        sizeUuid: rule.sizeUuid
      }
    ];
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        data
      })
    };

    const url = api(`/per-diem-due-date-free-day-rules`);

    try {
      await request(url, options);

      thunkAPI.dispatch(fetchPerDiemFreeDayRules());

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Per Diem Rule updated" />);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update Per Diem Rule" />);
    }
  }
);

export const deletePerDiemFreeDayRule = createAsyncThunk(
  'settings/perDiemFreeDays/delete',
  async (ruleUuid: string, thunkAPI) => {
    const data = [
      {
        uuid: ruleUuid
      }
    ];
    const options = {
      method: 'DELETE',
      body: JSON.stringify({
        data
      })
    };

    const url = api(`/per-diem-due-date-free-day-rules`);

    try {
      await request(url, options);

      thunkAPI.dispatch(fetchPerDiemFreeDayRules());

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Per Diem Rule updated" />);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update Per Diem Rule" />);
    }
  }
);

export const perDiemFreeDaysAdapter = createEntityAdapter<IPerDiemFreeDayRule>({
  selectId: (item: IPerDiemFreeDayRule) => item.uuid
});

const perDiemFreeDaysSlice = createSlice({
  name: 'settings/perDiemFreeDays',
  initialState: perDiemFreeDaysAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPerDiemFreeDayRules.fulfilled, (state, action) => {
      const data = action.payload || [];

      perDiemFreeDaysAdapter.setAll(state, data);
    });
  }
});

export const selectPerDiemFreeDays = (state: any) => state.settings.perDiem.perDiemRules;

export const { selectAll: selectAllPerDiemFreeDays } = perDiemFreeDaysAdapter.getSelectors<any>(selectPerDiemFreeDays);

export default perDiemFreeDaysSlice.reducer;
