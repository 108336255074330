import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { createSelector } from 'reselect';
import type { RootState } from '@client/reduxProvider';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

const baseName = 'dispatch/dryRunReasons';

interface DryRunReasonLegacy {
  attributes: {
    uuid: string;
    reason: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
}
interface DryRunReason {
  uuid: string;
  reason: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

const isLegacyDryRunReason = (dryRunReason: DryRunReasonLegacy | DryRunReason): dryRunReason is DryRunReasonLegacy =>
  'attributes' in dryRunReason;

const entityAdapter = createEntityAdapter<DryRunReason>({
  selectId: (dryRunReason: DryRunReason) => dryRunReason.uuid
});

export const fetchDryRunReasons = createAsyncThunk(`${baseName}/fetchAll`, async () => {
  const url = api(`/legs/dry-run-reasons`);

  try {
    const response = (await request(url, {
      method: 'GET'
    })) as { data: Array<DryRunReason | DryRunReasonLegacy> };

    return response.data.map((dryRunReason) => {
      if (isLegacyDryRunReason(dryRunReason)) {
        return dryRunReason.attributes;
      }

      return dryRunReason;
    });
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Dry Run Reasons" />);
  }
});

const slice = createSlice({
  name: baseName,
  initialState: { status: 'idle', ...entityAdapter.getInitialState() },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDryRunReasons.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      entityAdapter.setAll(state, action.payload || []);
    });
    builder.addCase(fetchDryRunReasons.pending, (state) => {
      state.status = 'pending';
    });
  }
});

const selectDryRunReasons = (state: RootState) => state.dispatch.dryRunReasons;

export const selectDryRunReasonDropdownOptions = createSelector(selectDryRunReasons, (dryRunReasonsSlice) =>
  dryRunReasonsSlice.ids
    .filter((id) => dryRunReasonsSlice.entities[id]!.deletedAt === null)
    .map((id) => ({
      label: dryRunReasonsSlice.entities[id]!.reason,
      value: id as string
    }))
);

export const selectDryRunReasonsIdle = createSelector(
  selectDryRunReasons,
  (dryRunReasonsSlice) => dryRunReasonsSlice.status === 'idle'
);

export const selectDryRunReasonLabel = (dryRunReasonUuid?: string) =>
  createSelector(selectDryRunReasons, (dryRunReasonsSlice) => {
    if (dryRunReasonUuid) {
      return dryRunReasonsSlice.entities[dryRunReasonUuid]?.reason;
    }
  });

export default slice.reducer;
