import { combineReducers } from '@reduxjs/toolkit';
import appointmentOutreach from './appointmentOutreachSlice';
import attachments from './attachments';
import bills from './bills';
import dryRunReasons from './dryRunReasonsSlice';
import graypoolEmpties from './graypoolEmpties';
import legs from './legs';
import legStopActions from './legStopActions';
import legStatuses from './legStatusesSlice';
import legTotalPriceOverrides from './legTotalPriceOverridesSlice';
import perDiemOverrides from './perDiemDueDateOverridesSlice';
import pricing from './pricing';
import orderMilestones from './orderMilestoneSlice';
import lineItemARAccessorialFeeReason from './lineItemARAccessorialFeeReason';
import orderPerDiem from './orderPerDiem';

const dispatchReducer = combineReducers({
  appointmentOutreach,
  attachments: attachments.reducer,
  bills: bills.reducer,
  dryRunReasons,
  graypoolEmpties: graypoolEmpties.reducer,
  legs: legs.reducer,
  legStopActions: legStopActions.reducer,
  legStatuses,
  legTotalPriceOverrides,
  perDiemOverrides,
  pricing: pricing.reducer,
  orderMilestones,
  lineItemARAccessorialFeeReason: lineItemARAccessorialFeeReason.reducer,
  orderPerDiem
});

export * from './appointmentOutreachSlice';
export * from './attachments';
export * from './bills';
export * from './dryRunReasonsSlice';
export * from './graypoolEmpties';
export * from './legs';
export * from './legStopActions';
export * from './legStatusesSlice';
export * from './legTotalPriceOverridesSlice';
export * from './perDiemDueDateOverridesSlice';
export * from './pricing';
export * from './orderMilestoneSlice';
export * from './lineItemARAccessorialFeeReason';
export * from './orderPerDiem';

export default dispatchReducer;
