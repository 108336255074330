import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SystemToast from '../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../components/Toaster';
import { getAuthToken } from '../../../../../../utils/auth';
import { handleCustomError } from '../../../../../../utils/errors';
import request from '../../../../../../utils/request';
import { api } from '../../../../../../utils/url';
import { Attachment } from './model';

export const fetchAttachmentsByLegUUIDs = createAsyncThunk(
  'attachments/fetchAttachmentsByLegUUIDs',
  async ({ legUUIDs }: { legUUIDs: string[] }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/legs/attachments?legUUIDs=${legUUIDs.join(',')}`);

    try {
      const attachmentsByLegUUID = (await request<Record<string, Attachment[]>>(url, options)) || {};
      return attachmentsByLegUUID;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch leg attachments')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchAttachmentsByOrderUuid = createAsyncThunk(
  'attachments/fetchAttachmentsByOrderUuids',
  async (orderUuid: string) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/orders/${orderUuid}/attachments`);

    try {
      const { data } = (await request<{ data: Attachment[] }>(url, options)) || { data: [] };

      return data;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch order attachments')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchAttachmentsByDepartmentUuid = createAsyncThunk(
  'attachments/fetchAttachmentsByDepartmentUuid',
  async (departmentUuid: string) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/attachments/department/${departmentUuid}`);

    try {
      const { data } = (await request<{ data: Attachment[] }>(url, options)) || { data: [] };

      return data;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch department attachments')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

interface AttachmentsSliceState {
  attachmentsByLegUUID: Record<string, Attachment[]>;
  attachmentsByOrderUuid: Record<string, Attachment[]>;
  attachmentsByDepartmentUuid: Record<string, Attachment[]>;
  loadingAttachmentsByLegUUID: boolean;
  loadingAttachmentsByOrderUuid: Record<string, boolean>;
  loadingAttachmentsByDepartmentUuid: Record<string, boolean>;
  loadingAttachmentsByType: boolean;
}
const initialState: AttachmentsSliceState = {
  attachmentsByLegUUID: {},
  attachmentsByOrderUuid: {},
  attachmentsByDepartmentUuid: {},
  loadingAttachmentsByLegUUID: false,
  loadingAttachmentsByOrderUuid: {},
  loadingAttachmentsByDepartmentUuid: {},
  loadingAttachmentsByType: false
};

const attachmentsSlice = createSlice({
  name: 'dispatch/attachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAttachmentsByLegUUIDs.pending, (state) => {
      state.loadingAttachmentsByLegUUID = true;
    });
    builder.addCase(fetchAttachmentsByLegUUIDs.rejected, (state) => {
      state.loadingAttachmentsByLegUUID = false;
    });
    builder.addCase(fetchAttachmentsByLegUUIDs.fulfilled, (state, action) => {
      const data = action.payload || {};

      state.attachmentsByLegUUID = {
        ...state.attachmentsByLegUUID,
        ...data
      };
      state.loadingAttachmentsByLegUUID = false;
    });
    builder.addCase(fetchAttachmentsByOrderUuid.pending, (state, action) => {
      state.loadingAttachmentsByOrderUuid[action.meta.arg] = true;
    });
    builder.addCase(fetchAttachmentsByOrderUuid.rejected, (state, action) => {
      state.loadingAttachmentsByOrderUuid[action.meta.arg] = false;
    });
    builder.addCase(fetchAttachmentsByOrderUuid.fulfilled, (state, action) => {
      const data = action.payload || [];

      state.attachmentsByOrderUuid = {
        ...state.attachmentsByOrderUuid,
        [action.meta.arg]: data
      };
      state.loadingAttachmentsByOrderUuid[action.meta.arg] = false;
    });
    builder.addCase(fetchAttachmentsByDepartmentUuid.pending, (state, action) => {
      state.loadingAttachmentsByDepartmentUuid[action.meta.arg] = true;
    });
    builder.addCase(fetchAttachmentsByDepartmentUuid.rejected, (state, action) => {
      state.loadingAttachmentsByDepartmentUuid[action.meta.arg] = false;
    });
    builder.addCase(fetchAttachmentsByDepartmentUuid.fulfilled, (state, action) => {
      const data = action.payload || [];

      state.attachmentsByDepartmentUuid = {
        ...state.attachmentsByDepartmentUuid,
        [action.meta.arg]: data
      };
      state.loadingAttachmentsByDepartmentUuid[action.meta.arg] = false;
    });
  }
});

const selectAttachmentsSlice = (state: any): AttachmentsSliceState =>
  state.dispatch.attachments as AttachmentsSliceState;

export const selectAttachmentsByLegUUID = createSelector([selectAttachmentsSlice], (data) => data.attachmentsByLegUUID);

export const selectLoadingAttachmentsByLegUUID = createSelector(
  [selectAttachmentsSlice],
  (data) => data.loadingAttachmentsByLegUUID
);

export const selectAttachmentsByOrderUuid = createSelector(
  [selectAttachmentsSlice],
  (data) => data.attachmentsByOrderUuid
);

export const selectLoadingAttachmentsByOrderUuid = createSelector(
  [selectAttachmentsSlice],
  (data) => data.loadingAttachmentsByOrderUuid
);

export const selectAttachmentsByDepartmentUuid = createSelector(
  [selectAttachmentsSlice],
  (data) => data.attachmentsByDepartmentUuid
);

export const selectLoadingAttachmentsByDepartmentUuid = createSelector(
  [selectAttachmentsSlice],
  (data) => data.loadingAttachmentsByDepartmentUuid
);

export default attachmentsSlice;
