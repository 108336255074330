// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csvListItem {
  display: list-item;
}

  .csvListItem__info {
    margin: 8px 0;
  }

  .csvListItem__info p {
      margin: 0 0 10px;
    }

  .csvListItem__download {
    margin: 0 0 0 8px;
    height: 18px;
    width: 18px;
    color: var(--color-blue-6);
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/ArAp/features/PerDiemDigest/Components/CsvListItem/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AAiBpB;;EAfE;IACE,aAAa;EAKf;;EAHE;MACE,gBAAgB;IAClB;;EAGF;IACE,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,0BAA0B;IAC1B,eAAe;EACjB","sourcesContent":[".csvListItem {\n  display: list-item;\n\n  &__info {\n    margin: 8px 0;\n\n    p {\n      margin: 0 0 10px;\n    }\n  }\n\n  &__download {\n    margin: 0 0 0 8px;\n    height: 18px;\n    width: 18px;\n    color: var(--color-blue-6);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
