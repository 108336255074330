import './style.css';
import '../SectionRow/style.css';

function SectionRowHeader() {
  const baseClassName = 'SectionRowHeader';
  const rowBaseClassName = 'SectionRow';

  return (
    <div className={baseClassName}>
      <div className={`${rowBaseClassName}-cell`}>Shipment ID</div>
      <div className={`${rowBaseClassName}-cell`}>Container Number</div>
      <div className={`${rowBaseClassName}-cell`}>Status</div>
      <div className={`${rowBaseClassName}-cell`}>Polled At</div>
    </div>
  );
}

export default SectionRowHeader;
