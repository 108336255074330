import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SystemToast from '../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../components/Toaster';
import { getAuthToken } from '../../../../../../utils/auth';
import { handleCustomError } from '../../../../../../utils/errors';
import request from '../../../../../../utils/request';
import { api } from '../../../../../../utils/url';
import { Bill } from './model';

export const fetchBillsByLegUUIDs = createAsyncThunk(
  'bills/fetchBillsByLegUUIDs',
  async ({ legUUIDs }: { legUUIDs: string[]; opts?: { keepExisting?: boolean } }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/legs/bills?legUUIDs=${legUUIDs.join(',')}`);

    try {
      const legsByOrderUUIDs = (await request(url, options)) as Record<string, Bill>;
      return legsByOrderUUIDs;
    } catch (err) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to fetch legs')} />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

interface BillsSliceState {
  billsByLegUUID: Record<string, Bill>;
  billsByUUID: Record<string, Bill>;
  loadingBillsByLegUUID: boolean;
}
const initialState: BillsSliceState = {
  billsByLegUUID: {},
  billsByUUID: {},
  loadingBillsByLegUUID: false
};

const billsSlice = createSlice({
  name: 'dispatch/bills',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBillsByLegUUIDs.pending, (state) => {
      state.loadingBillsByLegUUID = true;
    });
    builder.addCase(fetchBillsByLegUUIDs.rejected, (state) => {
      state.loadingBillsByLegUUID = false;
    });
    builder.addCase(fetchBillsByLegUUIDs.fulfilled, (state, action) => {
      const data = action.payload || {};
      const billsByUUID: Record<string, Bill> = action.meta.arg.opts?.keepExisting ? { ...state.billsByUUID } : {};
      const billsByLegUUID = action.meta.arg.opts?.keepExisting ? state.billsByLegUUID : {};

      Object.values(data).forEach((bill) => {
        billsByUUID[bill.uuid] = bill;
      });

      state.billsByLegUUID = {
        ...billsByLegUUID,
        ...data
      };
      state.billsByUUID = billsByUUID;
      state.loadingBillsByLegUUID = false;
    });
  }
});

const selectBillsSlice = (state: any) => state.dispatch.bills as BillsSliceState;

export const selectBillsByLegUUID = createSelector([selectBillsSlice], (data) => data.billsByLegUUID);

export const selectBillsByUUID = createSelector([selectBillsSlice], (data) => data.billsByUUID);

export const selectLoadingBillsByLegUUID = createSelector([selectBillsSlice], (data) => data.loadingBillsByLegUUID);

export default billsSlice;
