import { combineReducers } from '@reduxjs/toolkit';
import perDiem from '../features/PerDiemFreeDayRuless/store';

const settingsReducer = combineReducers({
  perDiem
});

export * from '../features/PerDiemFreeDayRuless/store';

export default settingsReducer;
