import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../../utils/url';
import request from '../../../../../utils/request';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

interface ResponseError {
  errors: any;
  reason: string;
  message: string;
}

export const createOrderCreationRequests = createAsyncThunk(
  'orderCreationRequests/create',
  async ({ orders, isFreeFlow = false }: { orders: any[]; isFreeFlow: boolean }, thunkApi) => {
    try {
      const fetchOptions = {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ data: orders })
      };

      const url = api(`/orders/request?isFreeFlow=${isFreeFlow}`);

      const result = await request(url, fetchOptions);

      raiseToast(
        <SystemToast
          type={SystemToast.Type.SUCCESS}
          message="Request to create orders accepted.  System is working on creating these orders."
        />
      );

      return result;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to create order(s)." />);

      return thunkApi.rejectWithValue(err);
    }
  }
);

interface CreateOrdersState {
  loading: boolean;
  error?: ResponseError;
}

const getInitialState = (): CreateOrdersState => ({ loading: false });

const createOrdersSlice = createSlice({
  name: 'createOrders',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    // create orderCreationRequests
    builder.addCase(createOrderCreationRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrderCreationRequests.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(createOrderCreationRequests.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ResponseError;
    });
  }
});

export default createOrdersSlice.reducer;
