import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SystemToast from '../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../components/Toaster';
import { getAuthToken } from '../../../../../../utils/auth';
import { handleCustomError } from '../../../../../../utils/errors';
import request from '../../../../../../utils/request';
import { api } from '../../../../../../utils/url';
import { Bid } from './models';

const getBundleByLegUUIDs = async (legUUIDs: string[], authHeader: string | null): Promise<any[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api(`/bundle?legUuids=${legUUIDs.join(',')}`);

  const bundle = (await request<Record<'data', any[]>>(url, options)) || { data: [] };
  return bundle.data;
};

export const createSingleLegBid = createAsyncThunk(
  'pricing/createSingleLegBid',
  async ({ carrierUUID, legUUID }: { carrierUUID: string; legUUID: string }): Promise<Bid[] | undefined> => {
    const authHeader = getAuthToken();

    /** We first need to find the bundle this belongs to so we can use it's uuid */
    const bundle = (await getBundleByLegUUIDs([legUUID], authHeader))[0];

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        data: [{ referenceId: bundle.uuid, legUUIDs: [legUUID], joinTypes: [], carrierUUID, includeLegPrices: true }]
      })
    };

    const url = api(`/pricing/bids`);

    try {
      const bid = (await request<{ data: Bid[] }>(url, options)) || { data: [] };
      return bid.data;
    } catch (err) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to create single bid')} />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchBidsByLegUUIDs = createAsyncThunk(
  'pricing/fetchBidsByLegUUIDs',
  async ({ legUUIDs }: { legUUIDs: string[] }) => {
    const authHeader = getAuthToken();

    const bundles = await getBundleByLegUUIDs(legUUIDs, authHeader);

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    try {
      // TODO: Fix this.
      // We should update this endpoint to allow for bulk ops
      const promises = bundles.map((b) => {
        const url = api(`/pricing/bids?referenceId=${b.uuid}&limit=1&order=DESC&successfullySold=true`);
        return request<{ data: Bid[] }>(url, options).then((res) => {
          if (res === undefined) {
            return {
              data: []
            };
          }
          return res;
        });
      });

      const resolvedPromises = await Promise.all(promises);
      const bids = resolvedPromises.reduce((accum: Bid[], bidData) => {
        accum.push(...bidData.data);
        return accum;
      }, []);
      return bids;
    } catch (err) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to create single bid')} />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchLegPriceEstimates = createAsyncThunk(
  'pricing/fetchLegPriceEstimates',
  async ({ legUUIDs }: { legUUIDs: string[] }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`pricing/estimate/legs?legUUIDs=${legUUIDs.join(',')}`);

    try {
      const estimates = (await request<Record<string, LegEstimate>>(url, options)) || {};
      return estimates;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to generate leg pricing estimates')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export interface LegEstimate {
  totalDrayagePrice: number;
  totalFSCPrice: number;
  totalAccessorialPrice: number;
  totalLegPrice: number;
}

interface PricingSliceState {
  loadingBidsByReferenceUUID: boolean;
  bidsByReferenceUUID: Record<string, Bid | undefined>;
  loadingBidEstimatesByLegUUID: boolean;
  bidEstimatesByLegUUID: Record<string, LegEstimate>;
}
const initialState: PricingSliceState = {
  loadingBidsByReferenceUUID: false,
  bidsByReferenceUUID: {},
  loadingBidEstimatesByLegUUID: false,
  bidEstimatesByLegUUID: {}
};

const pricingSlice = createSlice({
  name: 'dispatch/pricing',
  initialState,
  reducers: {
    reset: (state) => {
      state.bidsByReferenceUUID = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createSingleLegBid.pending, (state) => {
      state.loadingBidsByReferenceUUID = true;
    });
    builder.addCase(createSingleLegBid.rejected, (state) => {
      state.loadingBidsByReferenceUUID = false;
    });
    builder.addCase(createSingleLegBid.fulfilled, (state, action) => {
      const data = action.payload || [];

      const newBidsMap: Record<string, Bid> = {};
      data.forEach((bid) => {
        newBidsMap[bid.referenceId] = bid;
      });

      state.bidsByReferenceUUID = {
        ...state.bidsByReferenceUUID,
        ...newBidsMap
      };
      state.loadingBidsByReferenceUUID = false;
    });
    builder.addCase(fetchBidsByLegUUIDs.pending, (state) => {
      state.loadingBidsByReferenceUUID = true;
    });
    builder.addCase(fetchBidsByLegUUIDs.rejected, (state) => {
      state.loadingBidsByReferenceUUID = false;
    });
    builder.addCase(fetchBidsByLegUUIDs.fulfilled, (state, action) => {
      const data = action.payload || [];

      const newBidsMap: Record<string, Bid> = {};
      data.forEach((bid) => {
        newBidsMap[bid.referenceId] = bid;
      });

      state.bidsByReferenceUUID = {
        ...state.bidsByReferenceUUID,
        ...newBidsMap
      };
      state.loadingBidsByReferenceUUID = false;
    });
    builder.addCase(fetchLegPriceEstimates.pending, (state) => {
      state.loadingBidEstimatesByLegUUID = true;
    });
    builder.addCase(fetchLegPriceEstimates.rejected, (state) => {
      state.loadingBidEstimatesByLegUUID = false;
    });
    builder.addCase(fetchLegPriceEstimates.fulfilled, (state, action) => {
      const data = action.payload || {};

      state.bidEstimatesByLegUUID = {
        ...state.bidEstimatesByLegUUID,
        ...data
      };
      state.loadingBidEstimatesByLegUUID = false;
    });
  }
});

const selectBidsSlice = (state: any): PricingSliceState => state.dispatch.pricing as PricingSliceState;

/**
 * Bids
 */
export const selectLoadingBidsByReferenceUUID = createSelector(
  [selectBidsSlice],
  (data) => data.loadingBidsByReferenceUUID
);

export const selectBidsByReferenceId = createSelector([selectBidsSlice], (data) => data.bidsByReferenceUUID);

/**
 * Bid estimates
 */
export const selectLoadingBidEstimatesByLegUUID = createSelector(
  [selectBidsSlice],
  (data) => data.loadingBidEstimatesByLegUUID
);

export const selectBidEstimatesByLegUUID = createSelector([selectBidsSlice], (data) => data.bidEstimatesByLegUUID);

export const { reset: resetBidsSlice } = pricingSlice.actions;
export default pricingSlice;
