import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import * as notifications from '@client/_blessed/store/utils/toastNotifications';
import { LegStopAction, LegStopActionsState } from './interfaces';

const initialState: LegStopActionsState = {
  legStopActions: [] as LegStopAction[],
  isFetching: false,
  isLoading: false
};

const legStopActionsEndpoint = '/leg-stop/actions';

export const fetchLegStopActions = createAsyncThunk('legStopActions/fetchLegStopActions', async () => {
  const url = api(legStopActionsEndpoint);
  const options = {
    method: 'GET'
  };

  try {
    const response = await request(url, options);
    return response;
  } catch (err) {
    const message = 'Unable to fetch leg stop actions';
    notifications.sendFailureNotification(message);
  }
});

export const legStopActionsAdapter = createEntityAdapter<LegStopAction>({
  selectId: (legStopActions: LegStopAction) => legStopActions.uuid
});

const legStopActionsSlice = createSlice({
  name: 'legStop/legStopActions',
  initialState: legStopActionsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchLegStopActions.fulfilled, (state, action) => {
      state.isFetching = false;
      const data = action.payload as LegStopAction[];
      if (data) {
        legStopActionsAdapter.setAll(state, data);
      }
    });
    builder.addCase(fetchLegStopActions.pending, (state) => {
      state.isFetching = true;
    });
  }
});

export const selectLegStopActions = (state: any) => state.dispatch.legStopActions;

export const { selectAll: selectAllLegStopActions } = legStopActionsAdapter.getSelectors(selectLegStopActions);

export const selectLegStopActionsLoading = createSelector(selectLegStopActions, (state) => state.isFetching);

export const selectAllLegStopActionsByUuid = createSelector(selectAllLegStopActions, (legStopActions) =>
  legStopActions.reduce((accum: Record<string, LegStopAction>, legStopAcion) => {
    accum[legStopAcion.uuid] = legStopAcion;

    return accum;
  }, {})
);

export const selectChassisLegStopActions = createSelector(selectAllLegStopActions, (lsa) => {
  const result = lsa.reduce((acc: Record<string, string>, { uuid, display, target }) => {
    if (acc[display as keyof LegStopAction] === undefined && target === 'chassis-only') {
      acc[display] = uuid;
    }
    return acc;
  }, {});
  return result;
});

export const selectLegStopActionsByShorthandMap = createSelector(selectAllLegStopActions, (lsas) => {
  const result = lsas.reduce((acc: Record<string, LegStopAction>, lsa) => {
    acc[lsa.shortHand] = lsa;
    return acc;
  }, {});
  return result;
});

export * from './interfaces';

export default legStopActionsSlice;
