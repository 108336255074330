import { ReactElement, SyntheticEvent, useState, useEffect, useContext } from 'react';
import DownloadIcon from '@components/deprecatedTookit/icons/fa/regular/DownloadIcon';
import downloadjs from 'downloadjs';
import { retrieveFile } from '@client/state/resources/files/actions';
import { appActionContext } from '@client/components/App/contexts';
import { getImageFileSrc } from '@client/utils/data-processing/file';
import { sendFailureNotification } from '@client/_blessed/store/utils/toastNotifications';
import './styles.css';
import { Attachment } from '@client/_blessed/store/entities/attachments/entity';

interface Props {
  attachment: Attachment;
}

function CsvListItem({ attachment }: Props): ReactElement {
  const baseClassName = 'csvListItem';
  const appDispatchAction = useContext(appActionContext);

  const [fileSrc, setFileSrc] = useState('');
  const { attributes: attr } = attachment;

  async function retrieveFileUrl(): Promise<void> {
    try {
      const blob = await appDispatchAction(retrieveFile(attr.type, attr.fileUuid, attr.name));
      const src = await getImageFileSrc(blob);
      setFileSrc(src);
    } catch (err) {
      const msg = 'Failed to download file.';
      sendFailureNotification(msg);
    }
  }

  useEffect(() => {
    retrieveFileUrl();
  }, []);

  const handleDownload = (event: SyntheticEvent<EventTarget>) => {
    event.stopPropagation();
    downloadjs(fileSrc, attr.name, attr.type);
  };

  const fileDate = new Date(attachment.attributes.createdAt);

  return (
    <li className={`${baseClassName}`}>
      <div className={`${baseClassName}__info`}>
        <span>{attachment.attributes.name} </span>
        <DownloadIcon
          className={`${baseClassName}__download`}
          onClick={(e: SyntheticEvent<EventTarget>) => handleDownload(e)}
        />
        <div>Created at: {fileDate.toDateString()}</div>
      </div>
    </li>
  );
}

export default CsvListItem;
