// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csvList {
  margin-top: 15px;
}

  .csvList__order-list {
    padding-left: 1.3em;
  }

  .csvList__loader {
    width: 50px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/ArAp/features/PerDiemDigest/Components/CsvList/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AASlB;;EAPE;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".csvList {\n  margin-top: 15px;\n\n  &__order-list {\n    padding-left: 1.3em;\n  }\n\n  &__loader {\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
