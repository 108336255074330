import { getAuthToken } from '@client/utils/auth';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { sendFailureNotification, sendSuccessNotification } from '@client/_blessed/store/utils/toastNotifications';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { sliceId as arApSliceId } from '../../../store';

export const sliceId = 'perDiemCsvDigestSlice';

export interface PerDiemCsvDigestError {
  code: number;
  details: { data: string[] };
  error: string | null;
  message: string | null;
}

const initialState = { loading: false, error: {} };

export const postPerDiemCsvDigest = createAsyncThunk(
  'perDiemCsvDigest/postPerDiemCsvDigest',
  async (fileData: FileList) => {
    const formData = new FormData();
    const file = fileData[0];

    formData.append('file', file);
    formData.append('type', 'perDiemCsvDigest');
    formData.append('name', file.name);

    const url = api(`/actual-per-diem-csv`);
    const body = formData;
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body
    };

    try {
      const response = await request(url, options);
      return response;
    } catch (err) {
      const msg = 'Failed to digest Csv data!';
      sendFailureNotification(msg);
      return err;
    }
  }
);

export const perDiemCsvDigestAdapter = createEntityAdapter();

const orderPerDiemSlice = createSlice({
  name: 'arAp/perDiemCsvDigest',
  initialState: perDiemCsvDigestAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postPerDiemCsvDigest.fulfilled, (state, action) => {
      // We only want to handle errors
      if (action.payload.code !== 201) {
        const { responseObj }: { responseObj: PerDiemCsvDigestError } = action.payload || {};
        state.error = responseObj;
        state.loading = false;
      } else {
        const msg = 'Successfully digest file';
        state.loading = false;
        sendSuccessNotification(msg);
      }
    });
    builder.addCase(postPerDiemCsvDigest.pending, (state) => {
      state.loading = true;
      state.error = {};
    });
  }
});

const selectPerDiemCsvDigestSlice = (state: any) => state[arApSliceId][sliceId];

export const selectDigestErrors = createSelector(selectPerDiemCsvDigestSlice, (state) => state.error);
export const selectDigestLoading = createSelector(selectPerDiemCsvDigestSlice, (state) => state.loading);

export default orderPerDiemSlice.reducer;
