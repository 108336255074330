// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShipperSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

  .ShipperSection__shipper-name {
    display: flex;
    width: 100%;
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border: 1px solid #000;
  }

  .ShipperSection-rows-container {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .ShipperSection-rows-container-header {
      display: flex;
      width: 100%;
      background: #c5c5c5;
      padding: 5px;
      border: 1px solid #000;
    }

  .ShipperSection-row {
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid #000;
    padding: 10px;
    cursor: pointer;
  }

  .ShipperSection-row-item {
      width: 25%;
    }

  .ShipperSection__status {
    background: #eaeaea;
  }

  .ShipperSection__status-green {
      background: lightgreen;
    }

  .ShipperSection__status-red {
      background: lightcoral;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Edi/components/ShipperSection/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AAiDb;;EA/CE;IACE,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EASrB;;EAPE;MACE,aAAa;MACb,WAAW;MACX,mBAAmB;MACnB,YAAY;MACZ,sBAAsB;IACxB;;EAGF;IACE,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,eAAe;EAKjB;;EAHE;MACE,UAAU;IACZ;;EAGF;IACE,mBAAmB;EASrB;;EAPE;MACE,sBAAsB;IACxB;;EAEA;MACE,sBAAsB;IACxB","sourcesContent":[".ShipperSection {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  &__shipper-name {\n    display: flex;\n    width: 100%;\n    background: #f2f2f2;\n    padding: 10px;\n    margin-bottom: 5px;\n    cursor: pointer;\n    border: 1px solid #000;\n  }\n\n  &-rows-container {\n    margin-left: 10px;\n    margin-bottom: 10px;\n\n    &-header {\n      display: flex;\n      width: 100%;\n      background: #c5c5c5;\n      padding: 5px;\n      border: 1px solid #000;\n    }\n  }\n\n  &-row {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    border: 1px solid #000;\n    padding: 10px;\n    cursor: pointer;\n\n    &-item {\n      width: 25%;\n    }\n  }\n\n  &__status {\n    background: #eaeaea;\n\n    &-green {\n      background: lightgreen;\n    }\n\n    &-red {\n      background: lightcoral;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
