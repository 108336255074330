import { createSelector } from '@reduxjs/toolkit';
import Department from '@client/_blessed/store/entities/departments/model';
import { selectCustomersAlphabetically, selectAllDepartments } from '../../../../store/entities/departments';
import {
  isStopDepartment,
  isShippingDepartment,
  getDepartmentDisplayName
} from '../../../../../utils/data-processing/department';
import { createDepartmentLookupKey } from '../utils';

/* SELECTORS */
export const selectCreateOrders = (state: any) => state.createOrders;
export const selectCreateOrdersLoading = createSelector(selectCreateOrders, (state) => state.loading);
export const selectCreateOrdersError = createSelector(selectCreateOrders, (state) => state.error);

interface SelectCustomerColData {
  names: string[];
  customers: Department[];
  customersByLookupName: Record<string, Department>;
  customersByID: Record<string, Department>;
}

export const selectCustomerColData = createSelector(selectCustomersAlphabetically, (customers) => {
  if (customers) {
    const result: SelectCustomerColData = {
      names: [''],
      customers,
      customersByLookupName: {},
      customersByID: {}
    };

    const customersLen = customers.length;
    for (let customersIndex = 0; customersIndex < customersLen; customersIndex += 1) {
      const customer = customers[customersIndex];
      const resultName = getDepartmentDisplayName(customer);
      const lookupName = createDepartmentLookupKey(resultName, customer.shippingAddress?.street1, customer.companyUUID);

      result.customersByLookupName[lookupName] = customer;
      result.customersByID[customer.uuid] = customer;
      result.names.push(lookupName);
    }

    return result;
  }
});

export const selectStopDepartmentsColData = createSelector(selectAllDepartments, (departments) => {
  if (departments) {
    const result: any = {
      names: [''],
      departments: [],
      departmentsByLookupName: {},
      departmentsByID: {}
    };

    departments.forEach((department) => {
      if (isStopDepartment(department.type)) {
        const name = getDepartmentDisplayName(department);
        const resultName = name;
        const lookupName = createDepartmentLookupKey(
          resultName,
          department.shippingAddress?.street1,
          department.companyUUID
        );

        result.departmentsByLookupName[lookupName] = department;
        result.departmentsByID[department.uuid] = department;
        result.names.push(lookupName);
        result.departments.push(department);
      }
    });

    return result;
  }
});

export const selectShippingLineAccountNickNames = createSelector(selectAllDepartments, (departments) => {
  if (departments) {
    const result: any = [''];

    departments.forEach((department) => {
      if (isShippingDepartment(department.type)) {
        const name = getDepartmentDisplayName(department);

        result.push(name);

        // TODO: Idk...why?
        if (name) {
          result[name] = department; // why?
        }
      }
    });

    return result;
  }
});

export const selectDepartments = createSelector(selectAllDepartments, (departments) => {
  if (departments) {
    const result = ['', ...departments];

    return result;
  }
});
