import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TIME_ZONES } from '@drayalliance/types';
import { api } from '../../../../../utils/url';
import request from '../../../../../utils/request';
import { getJWT } from '../../../../../utils/auth';

interface RegisterCredentials {
  departmentType: string;
  firstName: string;
  lastName: string;
  company: string;
  shipperType: string;
  phone: string;
  email: string;
  password: string;
  billingStreet1: string;
  billingStreet2: string;
  billingCity: string;
  billingZipCode: string;
  billingState: string;
  billingCountry: string;
  estimatedImportVolume: string;
  timezone: TIME_ZONES;
}

export const requestRegister = createAsyncThunk(
  'register/request',
  async (credentials: RegisterCredentials, thunkApi) => {
    try {
      const {
        departmentType,
        firstName,
        lastName,
        company,
        phone,
        email,
        password,
        shipperType,
        billingStreet1,
        billingStreet2,
        billingCity,
        billingZipCode,
        billingState,
        billingCountry,
        estimatedImportVolume,
        timezone
      } = credentials;
      const payload = {
        departmentType,
        firstName,
        lastName,
        companyName: company,
        shipperType,
        mobilePhone: phone,
        email1: email,
        password,
        billingStreet1,
        billingStreet2,
        billingCity,
        billingZipCode,
        billingState,
        billingCountry,
        estimatedImportVolume,
        timezone
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      };

      const result = await request(api('/_/register'), options);

      return result;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

interface LoginRequest {
  email: string;
  password: string;
}

export const requestLogin = createAsyncThunk('register/login', async (login: LoginRequest) => {
  try {
    const authHeader = btoa(`${login.email}:${login.password}`);

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Basic ${authHeader}`
      },
      credentials: 'same-origin'
    };

    return await request(api('/login'), options);
  } catch (err) {
    return err;
  }
});

interface RegisterState {
  loading: boolean;
  items?: any;
  error?: any;
}

const getInitialState = (): RegisterState => ({ loading: false, items: getJWT() });

const registerSlice = createSlice({
  name: 'register',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestRegister.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestRegister.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(requestRegister.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const selectRegisterState = (state: any) => state.register;
export const selectRegisterIsLoading = createSelector(selectRegisterState, (state) => state.loading);
export const selectRegisterError = createSelector(selectRegisterState, (state) => state.error);

export default registerSlice.reducer;
