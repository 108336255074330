import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { useEffect } from 'react';
import { Redirect, RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import LoadingSpinner from '@client/components/LoadingSpinner/component';
import {
  fetchIngestedEDI204Messages,
  selectEDI204ShipperNames,
  selectEDI204ShippersFilter,
  selectEDIOrdersSliceLoading
} from './store/ediOrdersSlice';
import FiltersSection from './components/FiltersSection';
import ShipperSection from './components/ShipperSection/component';

import './style.css';

interface ParsedQueryString {
  transactionId?: string;
}

interface EDI204ListProps extends RouteChildrenProps {}

function Edi204List(props: EDI204ListProps) {
  const baseClassName = 'Edi204List';

  const dispatch = useAppDispatch();

  const edi204MessagesLoading = useSelector(selectEDIOrdersSliceLoading);
  const edi204ShipperNames = useSelector(selectEDI204ShipperNames);
  const edi204ShippersFilter = useSelector(selectEDI204ShippersFilter);

  const parsed: ParsedQueryString = qs.parse(props.location.search);

  useEffect(() => {
    const promise = dispatch(fetchIngestedEDI204Messages());

    return () => {
      promise.abort();
    };
  }, []);

  if (parsed.transactionId) {
    return <Redirect to={`/hub/dashboard/edi204/${parsed.transactionId}`} />;
  }

  if (edi204MessagesLoading) {
    return (
      <div className={`${baseClassName}__loading-container`}>
        <LoadingSpinner className={`${baseClassName}__loading`} />
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <div>
        <h1>EDI 204 Ingested Messages</h1>
      </div>
      <FiltersSection />
      <div className={`${baseClassName}__shipper-list`}>
        {edi204ShipperNames.map((shipperName) => {
          if (edi204ShippersFilter.length && !edi204ShippersFilter.includes(shipperName)) {
            return null;
          }

          return <ShipperSection key={shipperName} shipperName={shipperName} />;
        })}
      </div>
    </div>
  );
}

export default Edi204List;
