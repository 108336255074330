import { useState, ReactElement } from 'react';
import Switch from 'react-switch';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ScraperTypeName, TerminalUUID } from '@drayalliance/types';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/regular/CheckCircleIcon';
import TimesSquareIcon from '@components/deprecatedTookit/icons/fa/regular/TimesSquareIcon';
import * as constants from '@client/typedConstants';
import Button from '@client/components/Button';
import { TerminalScraperConfiguration } from '@client/_blessed/store/entities';
import FloatingActionsBar from '@client/components/FloatingActionsBar';
import { useAppUIActionContext } from '@client/components/App/contexts';
import { toggleAppModal } from '@client/state/ui/actions';
import DialogCard from '@client/components/DialogCard';
import { raiseToast, TOAST_POSITION } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import Department from '@client/_blessed/store/entities/departments/model';

import './style.css';

const {
  scraperTypeKeyToUIMapping,
  scraperTypeKeys
}: {
  scraperTypeKeyToUIMapping: Record<ScraperTypeName, string>;
  scraperTypeKeys: ScraperTypeName[];
} = constants;

interface TerminalAPIPageProps {
  loading: boolean;
  terminalScraperConfigurations: TerminalScraperConfiguration[];
  terminals: Department[];
  // eslint-disable-next-line no-unused-vars
  onUpdate: (updates: TerminalScraperConfiguration[], callback: () => void) => void;
}

interface ScraperConfigurationUpdate {
  availabilityEnabled?: boolean;
  regionScanEnabled?: boolean;
  outgateVerificationEnabled?: boolean;
  ingateVerificationEnabled?: boolean;
  appointmentBookingEnabled?: boolean;
  ingateAppointmentBookingEnabled?: boolean;
}

function TerminalAPIPage(props: TerminalAPIPageProps) {
  const [updatesMap, setUpdatesMap] = useState<Record<string, ScraperConfigurationUpdate>>({});
  const featureFlags = useFlags();
  const appUIDispatch = useAppUIActionContext();
  const className = 'scraper-configuration-page';
  const { allowUpdatesToScraperConfiguration, onHireTerminalShippingLineOverride } = featureFlags;

  const closeDialog = () => appUIDispatch(toggleAppModal(false));

  const submit = async () => {
    const updateUuids = Object.keys(updatesMap);
    const updates = updateUuids.map((uuid) => {
      const currentConfig = props.terminalScraperConfigurations.find((config) => config.uuid === uuid);
      const updateConfig = updatesMap[uuid];
      return { ...currentConfig, ...updateConfig } as TerminalScraperConfiguration;
    });
    if (updates.length) {
      props.onUpdate(updates, () => {
        setUpdatesMap({});
        closeDialog();
      });
    }
  };

  const handleOpenConfirmationPrompt = () => {
    if (allowUpdatesToScraperConfiguration) {
      appUIDispatch(
        toggleAppModal(
          true,
          <DialogCard className={`${className}__confirmation-dialog`} onClose={closeDialog}>
            <div className={`${className}__confirmation-dialog-title`}>Save Changes?</div>
            <div>
              Please confirm you want to proceed! Configuration updates have immediate and potentially expensive
              business consequences!
            </div>
            <div className={`${className}__confirmation-dialog-actions`}>
              <Button label="Cancel" onClick={closeDialog} />
              <Button theme="2" label="Confirm" onClick={submit} async disabled={!allowUpdatesToScraperConfiguration} />
            </div>
          </DialogCard>
        )
      );
    } else {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message="You do not have access to update Scraper Configuration" />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  };

  const toggleScraperEnabled = (scraperConfiguration: TerminalScraperConfiguration, scraperType: string) => {
    const stagedConfigurationUpdate = updatesMap[scraperConfiguration.uuid] || {};

    if (Object.getOwnPropertyDescriptor(stagedConfigurationUpdate, `${scraperType}Enabled`)) {
      delete stagedConfigurationUpdate[`${scraperType}Enabled` as keyof ScraperConfigurationUpdate];
    } else {
      stagedConfigurationUpdate[`${scraperType}Enabled` as keyof ScraperConfigurationUpdate] =
        !scraperConfiguration[`${scraperType}Enabled` as keyof TerminalScraperConfiguration];
    }

    setUpdatesMap((prevState) => {
      const newState = { ...prevState, [scraperConfiguration.uuid]: stagedConfigurationUpdate };
      if (!Object.keys(stagedConfigurationUpdate).length) {
        delete newState[scraperConfiguration.uuid];
      }
      return newState;
    });
  };

  const mapColumnHeaders = () => scraperTypeKeys.map((key) => <th key={key}>{scraperTypeKeyToUIMapping[key]}</th>);

  const mapTableRows = () => props.terminalScraperConfigurations.map((config) => {
      const terminalDepartment = props.terminals.find((terminal) => terminal.uuid === config.terminalUuid);
      return (
        <tr key={config.uuid}>
          <th>{terminalDepartment?.nickname}</th>
          {scraperTypeKeys.map((scraperType) => {
            const stagedConfig = updatesMap[config.uuid] || {};
            const scraperEnabled = Object.getOwnPropertyDescriptor(stagedConfig, `${scraperType}Enabled`)
              ? !!stagedConfig[`${scraperType}Enabled` as keyof ScraperConfigurationUpdate]
              : !!config[`${scraperType}Enabled` as keyof TerminalScraperConfiguration];
            const scraperSupported = !!config[`${scraperType}Supported` as keyof TerminalScraperConfiguration];
            let status = 'off';
            let icon = <TimesSquareIcon />;
            let toggle: ReactElement | string = 'Scraper not supported';
            let description: string | undefined;
            if (scraperEnabled) {
              status = 'on';
              icon = <CheckCircleIcon />;
            }
            if (scraperSupported) {
              if (allowUpdatesToScraperConfiguration) {
                toggle = (
                  <Switch
                    onChange={() => toggleScraperEnabled(config, scraperType)}
                    checked={scraperEnabled}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#24b248"
                    offColor="#ff0000"
                    width={32}
                    height={16}
                  />
                );
              } else {
                toggle = <div style={{ height: 16 }} />;
              }
            }

            if (scraperType === 'onHire') {
              if (config.terminalUuid === TerminalUUID.ITS && onHireTerminalShippingLineOverride.onHireITSOverride) {
                description = 'Auto On Hire: All SSLs';
              }
              if (config.terminalUuid === TerminalUUID.LBCT && onHireTerminalShippingLineOverride.OOCL_AT_PIER_E) {
                description = 'Auto On Hire: OOCL';
              }
            }

            return (
              <td className={status}>
                <div className="status">
                  {icon}
                  {status}
                </div>
                {toggle}
                {description && <div style={{ marginTop: '10px' }}> {description}</div>}
              </td>
            );
          })}
        </tr>
      );
    });

  const hasUpdates = !!Object.keys(updatesMap).length;

  return (
    <div className={className}>
      <div className={`${className}__title`}>Scraper Configuration</div>
      <div className={`${className}__body`}>
        <table className={`${className}__table`}>
          <thead>
            <tr>
              <th className={`${className}__table--zero`}>&nbsp;</th>
              {mapColumnHeaders()}
            </tr>
          </thead>
          <tbody>{mapTableRows()}</tbody>
        </table>
      </div>
      <FloatingActionsBar>
        <Button
          onClick={handleOpenConfirmationPrompt}
          theme="2"
          label="Save"
          style={{ width: '80px' }}
          loading={props.loading}
          disabled={!hasUpdates}
        />
      </FloatingActionsBar>
    </div>
  );
}

export default TerminalAPIPage;
