import React, { useState } from 'react';
import { raiseToast, TOAST_POSITION } from '@components/Toaster';

import SystemToast from '@components/SystemToast';
import FileUpload from '@components/FileSelectZone';
import { uploadBulkInvoice } from '@client/state/resources/invoices/actions';
import './style.css';
import ExcelExample from '@client/images/bulk-csv-upload-instructions-excel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

type BulkInvoiceState = {
  errors: Error[] | null;
  isBulkInvoicesUploading: boolean;
  uploadedFileName: string;
};

function BulkInvoiceGenerationPage() {
  const baseClassName = 'bulk-invoice-generation-page';

  const [state, setState] = useState<BulkInvoiceState>({
    errors: null,
    isBulkInvoicesUploading: false,
    uploadedFileName: ''
  });

  const handleFilesAccepted = async (acceptedFiles: any) => {
    try {
      setState((prevState) => ({
        ...prevState,
        errors: null,
        isBulkInvoicesUploading: true
      }));
      const result = await uploadBulkInvoice(acceptedFiles[0]);
      if (result.length === 0) {
        raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Bulk Invoice uploaded." />, {
          position: TOAST_POSITION.BOTTOM_LEFT
        });
      }

      setState((prevState) => ({
        ...prevState,
        isBulkInvoicesUploading: false,
        uploadedFileName: acceptedFiles[0].path,
        errors: result
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        errors: err.errors,
        isBulkInvoicesUploading: false,
        uploadedFileName: acceptedFiles[0].path
      }));
    }
  };

  return (
    <div className={baseClassName}>
      <h1>Bulk Invoice Generation</h1>
      <div className={`${baseClassName}__subtitle`}>
        Generate invoices automatically to be sent via the Shipper Invoice page. Upload a CSV with the order number and
        per diem fee amount (other line item types not currently supported). Each row in the CSV will generate a new
        invoice for the specified order number with a single per diem line item.
      </div>
      <div className={`${baseClassName}__csv-upload-container`}>
        <div className={`${baseClassName}__label`}>Upload CSV:</div>
        <div className={`${baseClassName}__file-upload`}>
          <FileUpload
            async
            isLoading={state.isBulkInvoicesUploading}
            accept="text/csv"
            onFilesAccepted={handleFilesAccepted}
          />
        </div>
        <div
          className={`${state.uploadedFileName ? `${baseClassName}__file` : ''} ${
            state.errors ? `${baseClassName}-error` : `${baseClassName}-ok`
          }`}
        >
          {state.uploadedFileName}
        </div>
      </div>
      <div className={`${baseClassName}__formatting`}>
        <div className={`${baseClassName}__formatting-instructions`}>
          <div className={`${baseClassName}__formatting-title`}>Formatting Instructions</div>
          <div className={`${baseClassName}__formatting-sub-title`}>Headers</div>
          <div className={`${baseClassName}__formatting-headers`}>Headers need to be configured using this format</div>
          <div className={`${baseClassName}__formatting-headers`}>(xyz_xyz_xyz).</div>
          <div className={`${baseClassName}__formatting-columns`}>
            <div className={`${baseClassName}__formatting-column`}>
              <span className={`${baseClassName}__formatting-bold`}>Col. 1 (required):</span> Order number. Format:
              numerical value only.
            </div>
            <div className={`${baseClassName}__formatting-column`}>
              <span className={`${baseClassName}__formatting-bold`}>Col. 2 (required):</span> Total per-diem amount in
              dollars. Format: xxxx.xx
            </div>
            <div className={`${baseClassName}__formatting-column`}>
              <span className={`${baseClassName}__formatting-bold`}>Col. 3 (optional):</span> Link to SSL Per diem
              invoice. Format: https://url.com
            </div>
            <div className={`${baseClassName}__formatting-column`}>
              <span className={`${baseClassName}__formatting-bold`}>Col. 4 (optional):</span> Invoice number from
              original steam shipping line invoice.
            </div>
          </div>
        </div>
        <div className={`${baseClassName}__formatting-example`}>
          <div className={`${baseClassName}__formatting-sub-title`}>Example:</div>
          <img
            data-tooltip-id="bulk-invoice-upload"
            src={ExcelExample}
            className={`${baseClassName}__excel-example`}
            alt="Bulk Invoice Upload instructions"
          />
        </div>
      </div>
      {state.errors && (
        <div className="bulk-invoice-generation-page__errors">
          <div className={`${baseClassName}__icon`}>
            <FontAwesomeIcon icon={faCircleXmark} fa-2x className={`${baseClassName}__icon`} />
          </div>
          {state.errors && Array.isArray(state.errors) && (
            <div className={`${baseClassName}__error-message-container}`}>
              <div className={`${baseClassName}__error-title`}>CSV file includes errors:</div>
              <div className={`${baseClassName}__error-text`}>
                <ul className={`${baseClassName}__ul`}>
                  {state.errors.map((err: any) => (
                    <li>{err}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(BulkInvoiceGenerationPage);
