import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@client/reduxProvider';
import { api } from '../../../../../utils/url';
import request from '../../../../../utils/request';
import { raiseToast, TOAST_POSITION } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';
import { handleCustomError } from '../../../../../utils/errors';

interface DeliveryAppointmentOutreachDetails {
  onTimeEarliestArrival?: string;
  onTimeLatestArrival?: string;
}

export const fetchDeliveryAppointmentOutreachDetails = createAsyncThunk(
  'dispatch/appointmentOutreach/fetchDeliveryAppointmentOutreachDetails',
  async (args: { apptStart: string; apptEnd: string; terminalUUID: string; consigneeUUID: string }) => {
    const url = api(
      `/orders/appointment-outreach/delivery-appt-details?apptStartTime=${args.apptStart}&apptEndTime=${args.apptEnd}&startLocationUUID=${args.terminalUUID}&endLocationUUID=${args.consigneeUUID}`
    );

    try {
      const items = (await request(url)) as DeliveryAppointmentOutreachDetails;

      return items;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch delivery appt details')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

interface DeliveryBatchDetailItem {
  containerNumber: string;
  previousApptStartTime: string;
  previousApptEndTime: string;
  directDeliveryDateStart: string;
  directDeliveryDateEnd: string;
}

/** @deprecated */
export interface DeliveryAppointmentBatchInfo {
  batch: DeliveryBatchDetailItem[];
  autosend: string | null;
}

type AppointmentType = 'directDelivery' | 'prepull';
export type AppointmentEmailTemplate = 'adviseOnDeliveryAppointment' | 'directDelivery';

interface PrepullBatchItem {
  containerNumber: string;
  legNumber: number;
  legUUID: string;
  emailTemplate: AppointmentEmailTemplate;
  autosend: string;
  prepullAppointmentStart: Date;
  prepullAppointmentEnd: Date;
}
interface DirectDeliveryBatchItem {
  containerNumber: string;
  legNumber: number;
  legUUID: string;
  emailTemplate: AppointmentEmailTemplate;
  autosend: string;
  previousAppointmentStartTime: Date;
  previousAppointmentEndTime: Date;
  directDeliveryDateStart: Date;
  directDeliveryDateEnd: Date;
}

type AppointmentBatchInfo = PrepullBatchItem | DirectDeliveryBatchItem;

export const fetchAppointmentBatchInfo = createAsyncThunk(
  'dispatch/appointmentOutreach/fetchAppointmentBatchInfo',
  async ({ consigneeUUID, zendeskID }: { consigneeUUID: string; zendeskID: number | null }) => {
    let urlString = `/report-batch/appointment-outreach/get?consigneeUUID=${consigneeUUID}`;

    if (zendeskID) {
      urlString += `&zendeskId=${zendeskID}`;
    }

    const url = api(urlString);

    try {
      const items = (await request(url)) as AppointmentBatchInfo[];

      return items;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch appointment batch info')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);
export interface DeliveryAppointmentAddBatchItem {
  // Required
  consigneeUUID: string;
  containerNumber: string;
  legNumber: number;
  legUUID: string;
  zendeskId: number | null;
  appointmentType: AppointmentType;
  emailTemplate: AppointmentEmailTemplate | null | undefined;

  // Direct delivery optionals
  previousAppointmentStartTime?: string;
  previousAppointmentEndTime?: string;
  directDeliveryDateStart?: string;
  directDeliveryDateEnd?: string;

  // Pre-pull optionals
  prepullAppointmentStart?: string;
  prepullAppointmentEnd?: string;
}
export const addAppointmentToBatch = createAsyncThunk(
  'dispatch/appointmentOutreach/addAppointmentToBatch',
  async (data: DeliveryAppointmentAddBatchItem[]) => {
    const url = api(`/report-batch/appointment-outreach/insert`);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data
      })
    };

    try {
      const items = (await request(url, options)) as any;

      return items;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to add appointment to the current consignee batch')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const sendReportBatch = createAsyncThunk(
  'dispatch/appointmentOutreach/sendReportBatch',
  async (sendItems: Record<'consigneeUUID' | 'zendeskId', string | number | null>[]) => {
    if (sendItems.length === 0) {
      return {};
    }

    const url = api(`/report-batch/appointment-outreach/send`);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: sendItems
      })
    };

    try {
      const items = (await request(url, options)) as any;

      return items;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to send email batches for consignees')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export interface AppointmentBatchInfoRecord {
  prepull: PrepullBatchItem[];
  directDelivery: DirectDeliveryBatchItem[];
}
interface AppointmentBatchState {
  deliveryApptDetailsLoading: boolean;
  deliveryBatchDetailsLoading: boolean;
  deliveryApptDetails: DeliveryAppointmentOutreachDetails;
  deliveryBatchDetails: DeliveryAppointmentBatchInfo;
  appointmentBatchDetailsLoading: boolean;
  appointmentBatchDetails: AppointmentBatchInfoRecord;
}

const getInitialState = (): AppointmentBatchState => ({
  deliveryApptDetailsLoading: false,
  deliveryBatchDetailsLoading: false,
  deliveryApptDetails: {},
  deliveryBatchDetails: {
    batch: [],
    autosend: null
  },
  appointmentBatchDetailsLoading: false,
  appointmentBatchDetails: {
    prepull: [],
    directDelivery: []
  }
});

const appointmentOutreachSlice = createSlice({
  name: 'dispatch/appointmentOutreach',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeliveryAppointmentOutreachDetails.pending, (state) => {
      state.deliveryApptDetailsLoading = true;
    });
    builder.addCase(fetchDeliveryAppointmentOutreachDetails.fulfilled, (state, action) => {
      state.deliveryApptDetailsLoading = false;
      state.deliveryApptDetails = action.payload ? action.payload : getInitialState().deliveryApptDetails;
    });
    builder.addCase(fetchDeliveryAppointmentOutreachDetails.rejected, (state) => {
      state.deliveryApptDetailsLoading = false;
    });
    builder.addCase(fetchAppointmentBatchInfo.pending, (state) => {
      state.appointmentBatchDetailsLoading = true;
    });
    builder.addCase(fetchAppointmentBatchInfo.fulfilled, (state, action) => {
      state.appointmentBatchDetailsLoading = false;
      if (action.payload) {
        state.appointmentBatchDetails = action.payload.reduce(
          (accum: AppointmentBatchInfoRecord, item) => {
            if ('prepullAppointmentStart' in item) {
              accum.prepull.push(item);
            } else {
              accum.directDelivery.push(item);
            }

            return accum;
          },
          { prepull: [], directDelivery: [] }
        );
      } else {
        state.appointmentBatchDetails = getInitialState().appointmentBatchDetails;
      }
    });
    builder.addCase(fetchAppointmentBatchInfo.rejected, (state) => {
      state.appointmentBatchDetailsLoading = false;
    });
  }
});

export const selectAppointmentOutreachDetails = (state: RootState) => state.dispatch.appointmentOutreach;

export const deliveryAppointmentOutreachDetails = createSelector(
  selectAppointmentOutreachDetails,
  (slice) => slice.deliveryApptDetails
);

/** @deprecated */
export const deliveryAppointmentBatchInfo = createSelector(
  selectAppointmentOutreachDetails,
  (slice) => slice.deliveryBatchDetails
);
export const deliveryAppointmentLoading = createSelector(
  selectAppointmentOutreachDetails,
  (slice) => slice.deliveryApptDetailsLoading
);

export const appointmentBatchInfo = createSelector(
  selectAppointmentOutreachDetails,
  (slice) => slice.appointmentBatchDetails
);

export const appointmentBatchInfoLoading = createSelector(
  selectAppointmentOutreachDetails,
  (slice) => slice.appointmentBatchDetailsLoading
);

export default appointmentOutreachSlice.reducer;
