import { combineReducers } from '@reduxjs/toolkit';
import perDiemRules from './perDiemFreeDaysSlice';
import containerSizes from './containerSizesSlice';

const perDiemReducer = combineReducers({
  perDiemRules,
  containerSizes
});

export * from './perDiemFreeDaysSlice';
export * from './containerSizesSlice';

export default perDiemReducer;
