import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  fetchTerminalScraperConfigurations,
  selectAllTerminalScraperConfigurations,
  selectTerminals,
  TerminalScraperConfiguration
} from '@client/_blessed/store/entities';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';

async function putTerminalScraperConfigurations(updates: any[]) {
  return request(api('/scraper-configuration'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: updates })
  });
}

function Container() {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const terminalScraperConfigurations = useSelector(selectAllTerminalScraperConfigurations);
  const terminals = useSelector(selectTerminals);

  const handleSubmit = async (updates: TerminalScraperConfiguration[], callback: () => void) => {
    try {
      setLoading(true);
      await putTerminalScraperConfigurations(updates);
      await dispatch(fetchTerminalScraperConfigurations());
      setLoading(false);
      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Scraper configurations updated." />);
      if (callback) {
        callback();
      }
    } catch (e) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update scraper configurations." />);
      setLoading(false);
    }
  };

  useEffect(() => {
    const promise = dispatch(fetchTerminalScraperConfigurations());

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <>
      <Helmet title="Scraper Config" />
      <Component
        terminalScraperConfigurations={terminalScraperConfigurations || []}
        terminals={terminals}
        onUpdate={handleSubmit}
        loading={loading}
      />
    </>
  );
}

export default React.memo(Container);
