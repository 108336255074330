import React from 'react';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../../utils/url';
import request from '../../../../../utils/request';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

export const fetchLegStatuses = createAsyncThunk('dispatch/legStatuses/fetchAll', async () => {
  const url = api(`/leg/statuses`);

  try {
    const items = (await request(url)) as string[];

    items.splice(1, 0, 'backhaul_ready');

    return items;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Leg Statuses" />);
    return [];
  }
});

export const fetchLegStatusesBefore = createAsyncThunk(
  'dispatch/legStatuses/fetchAllBefore',
  async (before: string) => {
    const url = api(`/leg/statuses?before=${before}`);

    try {
      const items = (await request(url)) as string[];

      /**
       * TODO:
       *   Since this is only current used for getting statuses before "started", inserting this is fine for now.
       *    This instead should probably check the "before" status in the odd chance someone is requesting a status that
       *    wouldn't have this before it
       */
      items.splice(1, 0, 'backhaul_ready');

      return { statuses: items, before };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Leg Statuses" />);
    }
  }
);

interface LegStatusesState {
  loading: boolean;
  statuses: string[];
  statusesBefore: { [key: string]: string[] };
}

const getInitialState = (): LegStatusesState => ({ loading: false, statuses: [], statusesBefore: {} });

const legStatusesSlice = createSlice({
  name: 'dispatch/legStatuses',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLegStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLegStatuses.fulfilled, (state, action) => {
      state.loading = false;
      state.statuses = action.payload;
    });
    builder.addCase(fetchLegStatuses.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchLegStatusesBefore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLegStatusesBefore.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const { statuses, before } = action.payload;

      state.loading = false;
      state.statusesBefore[before] = statuses;
    });
    builder.addCase(fetchLegStatusesBefore.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectLegStatuses = (state: any) => state.dispatch.legStatuses;

export const legStatusesSorted = createSelector(selectLegStatuses, (slice) => slice.statuses);
export const legStatusesSortedBefore = (status: string) =>
  createSelector(selectLegStatuses, (slice) => slice.statusesBefore[status] || []);

export default legStatusesSlice.reducer;
