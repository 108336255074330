import { api } from '@client/utils/url';
import { getAuthToken } from '@client/utils/auth';
import request from '@client/utils/request';
import { sendFailureNotification, sendSuccessNotification } from '@client/_blessed/store/utils/toastNotifications';

export class CSVHandler {
  static async handleSubmit(fileData: any) {
    const formData = new FormData();

    formData.append('file', fileData);
    formData.append('type', 'bulkInvoiceUpload');
    formData.append('name', 'whatever');

    let url = api(`/invoices/invoice-csv-parser`);
    const body = formData;
    let authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body
    };

    let validatedPayload;
    try {
      const response = await request(url, options);
      validatedPayload = response.data;
      if (response.errors && response.errors.length > 0) {
        const msg = 'CSV data was parsed but has errors.';
        sendFailureNotification(msg);
        return response.errors;
      }
    } catch (err) {
      const msg = 'Failed to parse Csv data!';
      sendFailureNotification(msg);
      return err;
    }

    if (validatedPayload.errors) {
      sendFailureNotification(JSON.stringify(validatedPayload.errors));
      return;
    }

    url = api(`/invoices/v2/add-to-order`);
    authHeader = getAuthToken();
    const postOptions: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authHeader}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      credentials: 'same-origin',
      body: `{ "data": ${JSON.stringify(validatedPayload.data)} }`
    };

    try {
      const response = await request(url, postOptions);
      sendSuccessNotification(
        `CSV successfully uploaded.

        You will be notified at your Dray Alliance email when processing is complete. This may take several minutes.`,
        'top-right'
      );
      return response;
    } catch (err) {
      const msg = 'Failed to create invoice!';
      sendFailureNotification(msg);
      return err;
    }
  }
}
