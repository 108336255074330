// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edi204List {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

  .Edi204List__loading {
    width: 100px;
    height: 100px;
  }

  .Edi204List__loading-container {
      display: flex;
      width: 100%;
      height: 100;
      align-items: center;
      justify-content: center;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Edi/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;AAcf;;EAZE;IACE,YAAY;IACZ,aAAa;EASf;;EAPE;MACE,aAAa;MACb,WAAW;MACX,WAAW;MACX,mBAAmB;MACnB,uBAAuB;IACzB","sourcesContent":[".Edi204List {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 10px;\n\n  &__loading {\n    width: 100px;\n    height: 100px;\n\n    &-container {\n      display: flex;\n      width: 100%;\n      height: 100;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
