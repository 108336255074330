import * as constants from '../../../../../../../constants';

export interface IPerDiemFreeDayRule {
  uuid: string;
  name: string;
  steamShippingLineAccountUuid: string;
  customerUuid: string;
  sizeUuid: string;
  effectiveDate: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  numOfFreeDays: number;
}

export const convertToLocalGlobalCustomerUuid = (rule: IPerDiemFreeDayRule) =>
  rule.uuid ? { ...rule } : { ...rule, customerUuid: constants.PER_DIEM_GLOBAL_CUSTOMER_UUID_LOCAL };

export const convertToDBGlobalCustomerUuid = (rule: IPerDiemFreeDayRule) =>
  rule.customerUuid === constants.PER_DIEM_GLOBAL_CUSTOMER_UUID_LOCAL
    ? { ...rule, customerUuid: constants.PER_DIEM_GLOBAL_CUSTOMER_UUID_DB }
    : { ...rule };
