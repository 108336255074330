import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Attachment } from '@client/_blessed/store/entities/attachments/entity';
import LoadingSpinner from '@client/components/LoadingSpinner/component';
import { selectAttachmentByTypeLoading } from '@client/_blessed/store/entities/attachments';
import CsvListItem from '../CsvListItem';
import './styles.css';

interface Props {
  perDiemCsvs: Attachment[];
}

function CsvList({ perDiemCsvs }: Props): ReactElement {
  const baseClassName = 'csvList';
  const perDiemCsvsLoading = useSelector(selectAttachmentByTypeLoading);

  return (
    <div className={baseClassName}>
      <h2>Uploaded Per Diem Csvs</h2>
      {perDiemCsvsLoading ? (
        <LoadingSpinner className={`${baseClassName}__loader`} />
      ) : (
        <ol className={`${baseClassName}__order-list`}>
          {perDiemCsvs?.length > 0 &&
            perDiemCsvs.map((attachment) => <CsvListItem key={attachment.id} attachment={attachment} />)}
        </ol>
      )}
    </div>
  );
}

export default CsvList;
