import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../../../../utils/url';
import request from '../../../../../../../utils/request';
import { raiseToast } from '../../../../../../../components/Toaster';
import SystemToast from '../../../../../../../components/SystemToast';

export const sliceId = 'shipperInvoices';

interface ResponseError {
  errors: any;
  reason: string;
  message: string;
}

export const invoiceShippers = createAsyncThunk(
  'shipperInvoices/invoiceShippers',
  async (invoiceIds: string[], thunkApi) => {
    try {
      const fetchOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      };

      const url = api('/report-batch/invoice-notification/send');

      const result = await request(url, fetchOptions);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Shipper(s) Invoiced." />);

      return result;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to invoice shipper(s)." />);

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchShipperInvoices = createAsyncThunk<InvoiceRecord>(
  'shipperInvoices/fetchShipperInvoices',
  async (_, thunkApi) => {
    try {
      const fetchOptions = {
        method: 'GET'
      };

      const url = api('/report-batch/invoice-notification');

      const result = await request(url, fetchOptions);
      return result as InvoiceRecord;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch shipper invoices." />);

      return thunkApi.rejectWithValue(err);
    }
  }
);

export interface InvoiceNotificationItem {
  containerNumber: string;
  orderNumber: string;
  customerTransportOrder: string;
  invoiceNumber: string;
  invoiceUUID: string;
  orderUUID: string;
  shipperName: string;
}
export interface Invoice {
  name: string;
  invoiceNotifications: InvoiceNotificationItem[];
  scheduledAt?: Date | string | null;
}
export type InvoiceRecord = Record<string, Invoice>;

export interface ShipperInvoicesState {
  creatingInvoices: boolean;
  error?: ResponseError;
  invoices: InvoiceRecord;
  fetchingInvoices: boolean;
}

const getInitialState = (): ShipperInvoicesState => ({
  creatingInvoices: false,
  fetchingInvoices: false,
  invoices: {}
});

const createShipperInvoices = createSlice({
  name: 'shipperInvoices',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(invoiceShippers.pending, (state) => {
      state.creatingInvoices = true;
    });
    builder.addCase(invoiceShippers.fulfilled, (state) => {
      state.creatingInvoices = false;
      state.error = undefined;
    });
    builder.addCase(invoiceShippers.rejected, (state) => {
      state.creatingInvoices = false;
    });
    builder.addCase(fetchShipperInvoices.pending, (state) => {
      state.fetchingInvoices = true;
    });
    builder.addCase(fetchShipperInvoices.fulfilled, (state, action) => {
      state.fetchingInvoices = false;
      if (action.payload) {
        state.invoices = action.payload;
      } else {
        state.invoices = getInitialState().invoices;
      }
    });
    builder.addCase(fetchShipperInvoices.rejected, (state) => {
      state.fetchingInvoices = false;
    });
  }
});

export default createShipperInvoices.reducer;
