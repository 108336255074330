import { combineReducers } from '@reduxjs/toolkit';
import ShipperInvoiceEmailStore, { sliceId as shipperInvoiceEmailSliceId } from '../features/ShipperInvoice/store';
import perDiemCsvDigest, { sliceId as perDiemCsvDigestSlice } from '../features/PerDiemDigest/store';

export const sliceId = 'arAp';

const arApReducer = combineReducers({
  [shipperInvoiceEmailSliceId]: ShipperInvoiceEmailStore,
  [perDiemCsvDigestSlice]: perDiemCsvDigest
});

export default arApReducer;
