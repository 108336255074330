import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@client/utils/auth';
import { sendSuccessNotification, sendFailureNotification } from '@client/_blessed/store/utils/toastNotifications';

/**
 * LineItemARAccessorialFeeReason
 * shortened: lineItemARReason
 */

export interface LineItemARReason {
  uuid: string;
  lineItemUUID: string;
  feeType: string;
  reason: string;
  notes: string;
}

export type LineItemARReasonByLineItemUuid = Record<string, LineItemARReason>;

const sliceId = 'dispatch/lineItemARReason';

export const fetchlineItemARReasonByUuids = createAsyncThunk(
  'lineItemARReason/fetch',
  async (lineItemUuids: string[]) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/line-item-ar-accessorial-fee-reasons/${lineItemUuids.join(',')}`);
    try {
      const response = await request(url, options);
      return response;
    } catch (err) {
      const message = 'Unable to fetch lineItemARReason';
      sendFailureNotification(message);
    }
  }
);

export const putlineItemARReason = createAsyncThunk(
  'lineItemARReason/put',
  async (lineItemARReason: LineItemARReason[]) => {
    if (!lineItemARReason.length) {
      return;
    }
    const url = api('/line-item-ar-accessorial-fee-reasons');
    const body = JSON.stringify({ data: lineItemARReason });
    const options = { method: 'PUT', body };
    try {
      const response = (await request(url, options)) as any;
      return response.data;
    } catch (err) {
      const { message } = (err as Error) || {};
      sendFailureNotification(message || 'Unable to create or update lineItemARReason.');
    }
  }
);

export const lineItemARReasonAdapter = createEntityAdapter<any>({
  selectId: (lineItemARReason: LineItemARReason) => lineItemARReason?.uuid
});

const lineItemARReasonSlice = createSlice({
  name: sliceId,
  initialState: lineItemARReasonAdapter.getInitialState(),
  reducers: {},
  // GET
  extraReducers: (builder) => {
    builder.addCase(fetchlineItemARReasonByUuids.fulfilled, (state, action) => {
      const data = action.payload || [];
      if (action.payload) {
        lineItemARReasonAdapter.setAll(state, data);
      }
    });

    // PUT
    builder.addCase(putlineItemARReason.fulfilled, (state, action) => {
      const data = action.payload || [];
      if (action.payload) {
        lineItemARReasonAdapter.upsertMany(state, data);
        const message = 'lineItemARReason created or updated!';
        sendSuccessNotification(message);
      }
    });
  }
});

const selectStore = (state: any) => state;
export const selectlineItemARReason = (state: any) => state.dispatch.lineItemARAccessorialFeeReason;

export const { selectAll: selectAllLineItemARReasonWithLineItemUuids, selectById: selectlineItemARReasonByUuid } =
  lineItemARReasonAdapter.getSelectors<any>(selectlineItemARReason);

export const selectLineItemARReasonByLineItemUuids = createSelector(
  selectAllLineItemARReasonWithLineItemUuids,
  (lineItemARReason: LineItemARReason[]): LineItemARReasonByLineItemUuid => {
    const map: LineItemARReasonByLineItemUuid = {};
    lineItemARReason.forEach((l) => {
      map[l.lineItemUUID] = l;
    });
    return map;
  }
);

export const selectLineItemARReasonByUuidFactory = (uuid: string) =>
  createSelector(selectStore, (state) => selectlineItemARReasonByUuid(state, uuid));

export default lineItemARReasonSlice;
