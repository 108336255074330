// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionRowHeader {
  display: flex;
  width: 100%;
  background: #c5c5c5;
  padding: 5px;
  border: 1px solid #000;
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Edi/components/ShipperSection/components/SectionRowHeader/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".SectionRowHeader {\n  display: flex;\n  width: 100%;\n  background: #c5c5c5;\n  padding: 5px;\n  border: 1px solid #000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
