import { importExportValues } from '../../../../constants';

export const columnIndexKeyMap: Record<string, number> = {
  containerNumber: 0,
  billingAccount: 1,
  urgency: 2,
  bookingBLNumber: 3,
  customerTO: 4,
  customerPO: 5,
  legType: 6,
  shippingLine: 7,
  terminal: 8,
  consigneeId: 9,
  ingateDropoffAccount: 10,
  vessel: 11,
  vesselEta: 12,
  lfd: 13,
  netWeight: 14,
  containerSize: 15,
  dropOrLive: 16,
  deliveryMethod: 17,
  zendeskTicketId: 18,
  invoiceDrayAndFSCTo: 19
} as const;

export const importExportValuesMap: Record<string, string> = {
  Import: importExportValues.import,
  Export: importExportValues.export,
  'Gray Pool Import': importExportValues.grayPoolImport
} as const;

export const dropPickColValuesMap: Record<string, string> = {
  Live: 'live',
  'Drop w/o Backhaul': 'drop_only',
  'Drop & Pick': 'droppick'
} as const;

export const deliveryMethodColValuesMap: Record<string, string> = {
  'Pierpass paid by customer': 'bycustomer',
  'Pierpass paid by DA': 'byda',
  'N/A': 'na'
} as const;
