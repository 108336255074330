import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SystemToast from '../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../components/Toaster';
import { getAuthToken } from '../../../../../../utils/auth';
import { handleCustomError } from '../../../../../../utils/errors';
import request from '../../../../../../utils/request';
import { api } from '../../../../../../utils/url';
import { GraypoolEmpty } from './model';

export const fetchGraypoolEmptiesByOrderUUIDs = createAsyncThunk(
  'graypoolEmpties/fetchGraypoolEmptiesByOrderUUIDs',
  async ({ orderUUIDs }: { orderUUIDs: string[] }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/orders/graypool-empties?orderUUIDs=${orderUUIDs.join(',')}`);

    try {
      const legsByOrderUUIDs = (await request<Record<string, GraypoolEmpty[]>>(url, options)) || {};
      return legsByOrderUUIDs;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch graypool empties')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchGraypoolEmptiesByOrderUUID = createAsyncThunk(
  'graypoolEmpties/fetchGraypoolEmptiesByOrderUUID',
  async ({ orderUUID }: { orderUUID: string }) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/orders/graypool-empties?orderUUIDs=${orderUUID}`);

    try {
      const legsByOrderUUIDs = await request<Record<string, GraypoolEmpty[]>>(url, options);
      return legsByOrderUUIDs;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch graypool empties')}
        />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

interface GraypoolEmptySliceState {
  graypoolEmptiesByOrderUUID: Record<string, GraypoolEmpty[]>;
  graypoolEmptiesByUUID: Record<string, GraypoolEmpty>;
  loadingGraypoolEmptiesByOrderUUID: boolean;
}
const initialState: GraypoolEmptySliceState = {
  graypoolEmptiesByOrderUUID: {},
  graypoolEmptiesByUUID: {},
  loadingGraypoolEmptiesByOrderUUID: false
};

const graypoolEmptiesSlice = createSlice({
  name: 'dispatch/graypoolEmpties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGraypoolEmptiesByOrderUUIDs.pending, (state) => {
      state.loadingGraypoolEmptiesByOrderUUID = true;
    });
    builder.addCase(fetchGraypoolEmptiesByOrderUUIDs.rejected, (state) => {
      state.loadingGraypoolEmptiesByOrderUUID = false;
    });
    builder.addCase(fetchGraypoolEmptiesByOrderUUIDs.fulfilled, (state, action) => {
      const data = action.payload || {};

      const graypoolEmptiesByUUID = Object.values(data).reduce(
        (accum: Record<string, GraypoolEmpty>, graypoolEmpties) => {
          graypoolEmpties.forEach((graypoolEmpty) => {
            accum[graypoolEmpty.uuid] = graypoolEmpty;
          });

          return accum;
        },
        {}
      );

      state.graypoolEmptiesByOrderUUID = data;
      state.graypoolEmptiesByUUID = graypoolEmptiesByUUID;
      state.loadingGraypoolEmptiesByOrderUUID = false;
    });
    builder.addCase(fetchGraypoolEmptiesByOrderUUID.fulfilled, (state, action) => {
      const data = action.payload || {};

      const graypoolEmptiesByUUID = Object.values(data).reduce(
        (accum: Record<string, GraypoolEmpty>, graypoolEmpties) => {
          graypoolEmpties.forEach((graypoolEmpty) => {
            accum[graypoolEmpty.uuid] = graypoolEmpty;
          });

          return accum;
        },
        {}
      );

      state.graypoolEmptiesByOrderUUID = {
        ...state.graypoolEmptiesByOrderUUID,
        ...data
      };
      state.graypoolEmptiesByUUID = {
        ...state.graypoolEmptiesByUUID,
        ...graypoolEmptiesByUUID
      };
    });
  }
});

const selectGraypoolEmptiesSlice = (state: any): GraypoolEmptySliceState =>
  state.dispatch.graypoolEmpties as GraypoolEmptySliceState;

export const selectGraypoolEmptiesByOrderUUID = createSelector(
  [selectGraypoolEmptiesSlice],
  (data) => data.graypoolEmptiesByOrderUUID
);

export const selectGraypoolEmptiesByUUID = createSelector(
  [selectGraypoolEmptiesSlice],
  (data) => data.graypoolEmptiesByUUID
);

export const selectLoadingGraypoolEmptiesByOrderUUID = createSelector(
  [selectGraypoolEmptiesSlice],
  (data) => data.loadingGraypoolEmptiesByOrderUUID
);

export default graypoolEmptiesSlice;
