import { EDI_204_ANSWER_CODES } from '@client/_blessed/components/features/EdiCreateOrders/store/ediCreateOrdersSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { EDIData, selectEDI204StatusFilter } from '../../../../store/ediOrdersSlice';

import './style.css';

interface SectionRowProps {
  message: EDIData;
}

function SectionRow({ message }: SectionRowProps) {
  const baseClassName = 'SectionRow';

  const edi204StatusFilter = useSelector(selectEDI204StatusFilter);

  const status = message.acknowledgementStatus ? EDI_204_ANSWER_CODES[message.acknowledgementStatus] : 'Pending';

  let rowStatusClass = `${baseClassName}__status`;
  switch (status) {
    case 'Acknowledged':
      rowStatusClass = `${baseClassName}__status-green`;
      break;
    case 'Rejected':
      rowStatusClass = `${baseClassName}__status-red`;
      break;
    default:
      break;
  }

  if (edi204StatusFilter.length && !edi204StatusFilter.includes(status as any)) {
    return null;
  }

  return (
    <Link
      className={`${baseClassName}-link`}
      to={`/hub/dashboard/edi204/${message.transactionId}`}
      key={message.transactionId}
    >
      <div className={[`${baseClassName}`, rowStatusClass].join(' ')}>
        <div className={`${baseClassName}-cell`}>{message.shipmentId}</div>
        <div className={`${baseClassName}-cell`}>{message.containerNumber}</div>
        <div className={`${baseClassName}-cell`}>{status}</div>
        <div className={`${baseClassName}-cell`}>
          {moment(message.polledAt).tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss z')}
        </div>
      </div>
    </Link>
  );
}

export default SectionRow;
