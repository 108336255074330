// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionRow {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
}

  .SectionRow-link {
    text-decoration: none;
  }

  .SectionRow-cell {
    width: 25%;
  }

  .SectionRow__status {
    background: #eaeaea;
  }

  .SectionRow__status-green {
      background: lightgreen;
    }

  .SectionRow__status-red {
      background: lightcoral;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Edi/components/ShipperSection/components/SectionRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,eAAe;AAqBjB;;EAnBE;IACE,qBAAqB;EACvB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,mBAAmB;EASrB;;EAPE;MACE,sBAAsB;IACxB;;EAEA;MACE,sBAAsB;IACxB","sourcesContent":[".SectionRow {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  border: 1px solid #000;\n  padding: 10px;\n  cursor: pointer;\n\n  &-link {\n    text-decoration: none;\n  }\n\n  &-cell {\n    width: 25%;\n  }\n\n  &__status {\n    background: #eaeaea;\n\n    &-green {\n      background: lightgreen;\n    }\n\n    &-red {\n      background: lightcoral;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
