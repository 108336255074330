import moment from 'moment';
import { checkboxIsChecked } from '../../../../utils/handsontable';
import {
  columnIndexKeyMap,
  importExportValuesMap,
  dropPickColValuesMap,
  deliveryMethodColValuesMap
} from './constants';
import isValidEtaOrLfd from '../../../../utils/isPickableDate';

const newLineRegEx = /\s+/g;
const trimRegEx = /^\s+|\s+$/g;

export function createDepartmentLookupKey(
  departmentName?: string | null,
  street?: string | null,
  companyId?: string | null
) {
  let result = '';

  if (departmentName) {
    result += departmentName.replace(newLineRegEx, ' ').replace(trimRegEx, '');
  }

  if (street) {
    result += ` / ${street.replace(newLineRegEx, ' ').replace(trimRegEx, '')}`;
  }

  if (companyId) {
    result += ` / companyId=${companyId}`;
  }

  return result;
}

function isContainerOverweight(size?: string, weight?: string) {
  let result = false;

  if (size && weight) {
    const numericSize = parseInt(size, 10);
    const numericWeight = parseInt(weight, 10);

    if ((numericSize <= 20 && numericWeight > 37500) || (numericSize >= 40 && numericWeight > 46500)) {
      result = true;
    }
  }

  return result;
}

export function createOrdersFromTableRowData(
  tableData: any,
  {
    customerColData,
    stopDepartmentsColData,
    shippingLineAccountNickNames,
    maxEtaAndLfdBookableDays = null,
    rowIndexes = []
  }: any
) {
  const overweightOrders: any[] = [];
  const invalidEtaOrders: any[] = [];
  const invalidLfdOrders: any[] = [];
  const orders = [];
  const tableDataLen = tableData.length;

  for (let tableDataIndex = 0; tableDataIndex < tableDataLen; tableDataIndex += 1) {
    const rowData: any = tableData[tableDataIndex];
    const billingAccountRowData = rowData[columnIndexKeyMap.billingAccount];
    const billingAccountUuid = customerColData.customersByLookupName[billingAccountRowData].uuid;
    const invoiceDrayAndFSCToRowData = rowData[columnIndexKeyMap.invoiceDrayAndFSCTo];
    const invoiceDrayAndFSCTo =
      invoiceDrayAndFSCToRowData &&
      customerColData.customersByLookupName[invoiceDrayAndFSCToRowData] &&
      customerColData.customersByLookupName[invoiceDrayAndFSCToRowData].uuid;
    const consigneeName = rowData[columnIndexKeyMap.consigneeId];
    let consigneeId = null;

    if (consigneeName) {
      consigneeId = stopDepartmentsColData.departmentsByLookupName[consigneeName].uuid;
    }

    let steamShippingLineAccountUuid = shippingLineAccountNickNames[rowData[columnIndexKeyMap.shippingLine]];
    steamShippingLineAccountUuid = steamShippingLineAccountUuid && steamShippingLineAccountUuid.uuid;
    const pickupAccountUuid = stopDepartmentsColData.departmentsByLookupName[rowData[columnIndexKeyMap.terminal]].uuid;

    // Create order data.
    const lfdMoment = moment(rowData[columnIndexKeyMap.lfd]);
    const etaOrEpdMoment = moment(rowData[columnIndexKeyMap.vesselEta], 'MM/DD/YYYY');
    const etaOrEpd = etaOrEpdMoment.format('YYYY-MM-DD');
    const lfd = rowData[columnIndexKeyMap.lfd] && lfdMoment.isValid() && lfdMoment.format('YYYY-MM-DD');
    const containerSizeInfo = rowData[columnIndexKeyMap.containerSize];
    const containerSize = containerSizeInfo;
    const tableRowIndex = rowIndexes[tableDataIndex];

    // Required fields.

    const order: Record<string, any> = {
      billingAccountUuid, // Customer account
      bookingBL: rowData[columnIndexKeyMap.bookingBLNumber],
      containerNumber: rowData[columnIndexKeyMap.containerNumber],
      deliveryAccountUuid: consigneeId,
      importExport: importExportValuesMap[rowData[columnIndexKeyMap.legType]],
      pickupAccountUuid,
      steamShippingLineAccountUuid,
      steamShipName: rowData[columnIndexKeyMap.vessel],
      size: containerSize,
      hot: checkboxIsChecked(rowData[columnIndexKeyMap.urgency]),
      deliveryType: dropPickColValuesMap[rowData[columnIndexKeyMap.dropOrLive]],
      shipArrivalEta: etaOrEpd,
      earliestPickupDate: etaOrEpd,
      // default to null here so we don't try sending "" to the backend if they clear this field out or paste in from a spreadsheet that has this empty
      zendeskTicketId: rowData[columnIndexKeyMap.zendeskTicketId] || null,

      // TODO: need to find a better way to get the trucking companies account
      // perhaps if account is carrier use the active department
      truckingCompanyAccountUuid: 'd21616a9-0896-7ca5-10ca-5c9ac4215f93',
      ...(invoiceDrayAndFSCTo ? { invoiceDrayAndFSCTo } : {})
    };

    // Optional fields.

    if (lfd && (order.importExport === 'import' || order.importExport === 'grayPoolImport')) {
      // Import: Last day to pick up the container for free.
      order.lastFreeDay = lfd;
    } else if (lfd) {
      // Export: Ship leaves.
      order.cutoffDay = lfd;
    }

    if (rowData[columnIndexKeyMap.customerTO]) {
      order.customerTransportOrder = rowData[columnIndexKeyMap.customerTO];
    }

    if (rowData[columnIndexKeyMap.customerPO]) {
      order.customerPurchaseOrder = rowData[columnIndexKeyMap.customerPO];
    }

    // Add optional order data.
    if (rowData[columnIndexKeyMap.ingateDropoffAccount]) {
      const ingateDropoffAccountUUID =
        stopDepartmentsColData.departmentsByLookupName[rowData[columnIndexKeyMap.ingateDropoffAccount]].uuid;

      order.returnAccountUuid = ingateDropoffAccountUUID;
    }

    // Add optional container data.
    if (rowData[columnIndexKeyMap.netWeight]) {
      order.weight = rowData[columnIndexKeyMap.netWeight].replace(',', '');

      if (isContainerOverweight(containerSize, order.weight)) {
        overweightOrders.push({ ...order, tableRowIndex });
      }
    }

    const vesselEta = rowData[columnIndexKeyMap.vesselEta];
    if (!isValidEtaOrLfd(vesselEta, maxEtaAndLfdBookableDays)) {
      invalidEtaOrders.push({ ...order, tableRowIndex });
    }

    const lfdCellData = rowData[columnIndexKeyMap.lfd];
    if (!isValidEtaOrLfd(lfdCellData, maxEtaAndLfdBookableDays)) {
      invalidLfdOrders.push({ ...order, tableRowIndex });
    }

    if (rowData[columnIndexKeyMap.deliveryMethod]) {
      order.pierpass = deliveryMethodColValuesMap[rowData[columnIndexKeyMap.deliveryMethod]];
    }

    if (rowData[columnIndexKeyMap.customerInstruction]) {
      order.customerComment = rowData[columnIndexKeyMap.customerInstruction];
    }

    if (rowData[columnIndexKeyMap.customerPrepayDays]) {
      order.chassisPrepayDays = rowData[columnIndexKeyMap.customerPrepayDays];
    }

    // Add order to orders list.
    orders.push({
      type: 'order',
      attributes: order
    });
  }

  return {
    orders,
    overweightOrders: overweightOrders.length > 0 ? overweightOrders : null,
    invalidEtaOrders: invalidEtaOrders.length > 0 ? invalidEtaOrders : null,
    invalidLfdOrders: invalidLfdOrders.length > 0 ? invalidLfdOrders : null
  };
}

export function checkIfRowHasNoValues(row: any): boolean {
  const { containerNumber, ...rowWithoutContainerNumber } = row;
  const allEmpty = Object.keys(rowWithoutContainerNumber).every((key) => {
    const cell = rowWithoutContainerNumber[key];
    return !cell;
  });
  return allEmpty;
}

/**
 *
 * @param {row} rows;
 * @returns string[]
 */
export function getCurrentContainerNumbers(rows: Array<Record<'containerNumber', string>>): string[] {
  const containerNumbers = rows.map((row) => {
    const { containerNumber } = row || '';
    return containerNumber?.trim();
  });
  return containerNumbers;
}
