import { ReactElement } from 'react';
import { PerDiemCsvDigestError } from '../../store';
import './styles.css';

function ErrorMessage(props: Partial<PerDiemCsvDigestError>): ReactElement {
  const baseClassName = 'errorMessage';

  return (
    <div className={baseClassName}>
      <p className={`${baseClassName}__text`}>{props.message}</p>
      {props.details && <div className={`${baseClassName}__data`}>{props.details?.data.join(', ')}</div>}
    </div>
  );
}

export default ErrorMessage;
