import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import type { RootState } from '@client/reduxProvider';
import { getAuthToken } from '@client/utils/auth';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { EDI204AnswerCode } from '../../EdiCreateOrders/store/types';

export const fetchIngestedEDI204Messages = createAsyncThunk('ediOrders/fetchIngestedEdi204Messages', async () => {
  const authHeader = getAuthToken();

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    }
  };

  const url = api(`/edi/204`);
  try {
    const result = (await request(url, options)) as { data: Record<string, EDIData[]>; shipperNames: string[] };

    return result;
  } catch (error) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch EDI order info" />);
  }
});

export interface EDIData {
  acknowledgementStatus: EDI204AnswerCode | null;
  containerNumber: string;
  customerName: string;
  transactionId: string;
  shipmentId: string;
  polledAt: string;
}
interface EDIOrdersStateSlice {
  edi204MessasgesByShipper: Record<string, EDIData[]>;
  edi204ShipperNames: string[];
  loadingEdi204Messages: boolean;
  filters: {
    shipper: string[];
    status: Array<'Acknowledged' | 'Rejected' | 'Pending'>;
  };
}

const initialState: EDIOrdersStateSlice = {
  edi204MessasgesByShipper: {},
  edi204ShipperNames: [],
  loadingEdi204Messages: false,
  filters: {
    shipper: [],
    status: []
  }
};

const ediOrdersSlice = createSlice({
  name: 'ediOrders',
  initialState,
  reducers: {
    setShippersFilter: (state, action) => {
      state.filters.shipper = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    resetAllFilters: (state) => {
      state.filters = initialState.filters;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIngestedEDI204Messages.pending, (state) => {
      state.loadingEdi204Messages = true;
    });
    builder.addCase(fetchIngestedEDI204Messages.fulfilled, (state, action) => {
      state.loadingEdi204Messages = false;
      state.edi204MessasgesByShipper = action.payload?.data || initialState.edi204MessasgesByShipper;
      state.edi204ShipperNames = action.payload?.shipperNames || initialState.edi204ShipperNames;
    });
    builder.addCase(fetchIngestedEDI204Messages.rejected, (state) => {
      state.loadingEdi204Messages = false;
    });
  }
});

const selectEDIOrdersSlice = (state: RootState) => state.ediOrders;

export const selectEDIOrdersSliceLoading = createSelector(selectEDIOrdersSlice, (state) => state.loadingEdi204Messages);
export const selectEDI204MessagesByShipper = createSelector(
  selectEDIOrdersSlice,
  (state) => state.edi204MessasgesByShipper
);
export const selectEDI204ShipperNames = createSelector(selectEDIOrdersSlice, (state) => state.edi204ShipperNames);

export const selectEDI204ShippersFilter = createSelector(selectEDIOrdersSlice, (state) => state.filters.shipper);
export const selectEDI204StatusFilter = createSelector(selectEDIOrdersSlice, (state) => state.filters.status);

export const { setShippersFilter, setStatusFilter, resetAllFilters } = ediOrdersSlice.actions;

export default ediOrdersSlice.reducer;
