import Select from 'react-dropdown-select';
import { useMemo } from 'react';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  resetAllFilters,
  selectEDI204ShipperNames,
  selectEDI204ShippersFilter,
  selectEDI204StatusFilter,
  setShippersFilter,
  setStatusFilter
} from '../../store/ediOrdersSlice';

import './style.css';

const STATUS_FILTER_OPTIONS = [
  { label: 'Acknowledged', value: 'Acknowledged' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Pending', value: 'Pending' }
];

function FiltersSection() {
  const baseClassName = 'FiltersSection';

  const dispatch = useAppDispatch();

  const edi204ShipperNames = useSelector(selectEDI204ShipperNames);
  const edi204ShippersFilter = useSelector(selectEDI204ShippersFilter);
  const edi204StatusFilter = useSelector(selectEDI204StatusFilter);

  const shipperFilterOptions = useMemo(() => {
    if (!edi204ShipperNames.length) {
      return [];
    }

    return edi204ShipperNames.map((shipper) => ({ label: shipper, value: shipper }));
  }, [edi204ShipperNames]);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__section`}>
        Shipper:
        <Select
          placeholder="Select Shipper"
          className={`${baseClassName}__section-item`}
          options={shipperFilterOptions}
          values={edi204ShippersFilter.map((shipper) => ({ label: shipper, value: shipper }))}
          onChange={(value) => {
            dispatch(setShippersFilter(value.map((v) => v.value)));
          }}
          multi
        />
      </div>
      <div className={`${baseClassName}__section`}>
        Status:&nbsp;
        <Select
          placeholder="Select Status"
          className={`${baseClassName}__section-item`}
          options={STATUS_FILTER_OPTIONS}
          values={edi204StatusFilter.map((status) => ({ label: status, value: status }))}
          onChange={(value) => {
            dispatch(setStatusFilter(value.map((v) => v.value as any)));
          }}
          multi
        />
      </div>
      <div className={`${baseClassName}__section`}>
        <div
          className={`${baseClassName}-button`}
          onClick={() => {
            dispatch(resetAllFilters());
          }}
        >
          Clear All Filters
        </div>
      </div>
    </div>
  );
}

export default FiltersSection;
