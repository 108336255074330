import React from 'react';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../../../../../utils/url';
import request from '../../../../../../../utils/request';
import { raiseToast } from '../../../../../../../components/Toaster';
import SystemToast from '../../../../../../../components/SystemToast';

export const fetchContainerSizes = createAsyncThunk('settings/containerSizes/fetchAll', async () => {
  const url = api(`/chassis-sizes`);

  try {
    const result = (await request(url)) as { data: any[] };
    const items = result.data;

    return items;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Container Sizes" />);
  }
});

export const containerSizesAdapter = createEntityAdapter<any>({
  selectId: (item: any) => item.uuid
});

const containerSizesSlice = createSlice({
  name: 'settings/containerSizes',
  initialState: containerSizesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContainerSizes.fulfilled, (state, action) => {
      const data = action.payload || [];

      containerSizesAdapter.setAll(state, data);
    });
  }
});

export const selectContainerSizes = (state: any) => state.settings.perDiem.containerSizes;

export const { selectAll: selectAllContainerSizes } = containerSizesAdapter.getSelectors<any>(selectContainerSizes);

export default containerSizesSlice.reducer;
