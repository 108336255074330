import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { OrderPerDiem } from './model';

export const fetchOrderPerDiemByOrderUUID = createAsyncThunk(
  'orderPerDiem/fetchOrderPerDiemByOrderUUID',
  async (orderUUID: string) => {
    const url = api(`/order-per-diem/${orderUUID}`);
    try {
      const response = await request(url);
      return response.data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Order's Per Diem Estimates" />);
    }
  }
);

export const orderPerDiemAdapter = createEntityAdapter({
  selectId: (orderPerDiem: OrderPerDiem) => orderPerDiem.orderUuid
});

const orderPerDiemSlice = createSlice({
  name: 'dispatch/orderPerDiem',
  initialState: orderPerDiemAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrderPerDiemByOrderUUID.fulfilled, (state, action) => {
      if (action.payload) {
        orderPerDiemAdapter.upsertOne(state, action.payload);
      }
    });
  }
});

const selectStore = (state: any) => state;

export const selectOrderPerDiem = (state: any) => state.dispatch.orderPerDiem;

export const { selectById: selectOrderPerDiemByOrderUUID } = orderPerDiemAdapter.getSelectors<any>(selectOrderPerDiem);

export const orderPerDiemByOrderUUIDSelectorFactory = (orderUUID: string) =>
  createSelector(selectStore, (state) => selectOrderPerDiemByOrderUUID(state, orderUUID));

export default orderPerDiemSlice.reducer;
