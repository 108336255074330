// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiltersSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

  .FiltersSection__section {
    display: flex;
    width: 100%;
    margin-right: 25px;
    margin-bottom: 10px;
  }

  .FiltersSection__section:last-child {
      margin-bottom: 0;
    }

  .FiltersSection__section-item {
      width: 100%;
      margin-left: 10px;
    }

  .FiltersSection__section-item * {
        cursor: pointer;
      }

  .FiltersSection-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 40px;
    background: #f2f2f2;
    border: 1px solid #000;
    cursor: pointer;
    min-width: 125px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Edi/components/FiltersSection/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AAiCb;;EA/BE;IACE,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EAcrB;;EAZE;MACE,gBAAgB;IAClB;;EAEA;MACE,WAAW;MACX,iBAAiB;IAKnB;;EAHE;QACE,eAAe;MACjB;;EAIJ;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".FiltersSection {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  width: 100%;\n\n  &__section {\n    display: flex;\n    width: 100%;\n    margin-right: 25px;\n    margin-bottom: 10px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    &-item {\n      width: 100%;\n      margin-left: 10px;\n\n      & * {\n        cursor: pointer;\n      }\n    }\n  }\n\n  &-button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 10%;\n    height: 40px;\n    background: #f2f2f2;\n    border: 1px solid #000;\n    cursor: pointer;\n    min-width: 125px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
