import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FileUpload from '@client/components/FileSelectZone';
import { appActionContext, useAppStateContext } from '@client/components/App/contexts';
import { createAttachment } from '@client/state/resources/attachments/actions';
import { isEmpty } from 'lodash';
import { sendFailureNotification } from '@client/_blessed/store/utils/toastNotifications';
import PageActionBar from '@client/components/PageActionBar/component';
import {
  fetchAttachmentsByType,
  selectSortedAttachmentByCsvPerDiem
} from '@client/_blessed/store/entities/attachments';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { postPerDiemCsvDigest, selectDigestLoading, selectDigestErrors } from './store';
import ErrorMessage from './Components/ErrorMessage/component';
import CsvList from './Components/CsvList/component';
import './styles.css';

function PerDiemPage() {
  const baseClassName = 'actualPerDiemPage';
  const appState = useAppStateContext();
  const appDispatchAction = useContext(appActionContext);
  const userInfo = appState['auth.userInfo'];

  const dispatch = useAppDispatch();

  const userId = userInfo?.uuid;
  const digestErrors = useSelector(selectDigestErrors);
  const digestLoading = useSelector(selectDigestLoading);
  const perDiemCsvs = useSelector(selectSortedAttachmentByCsvPerDiem);

  const fetchAttachments = fetchAttachmentsByType({ type: 'perDiemCsvDigest' });

  useEffect(() => {
    dispatch(fetchAttachments);
  }, []);

  const uploadFileToS3 = async (fileData: FileList) => {
    try {
      const formData = new FormData();
      const file = fileData[0];

      formData.append('file', file);
      formData.append('ownerType', 'lineItem');
      formData.append('ownerUuid', userId as string);
      formData.append('type', 'perDiemCsvDigest');
      formData.append('name', file.name);

      await appDispatchAction(createAttachment(formData));
    } catch (error) {
      const msg = 'Failed to attach file!';
      sendFailureNotification(msg);
    }
  };

  const handleSubmit = async (fileData: FileList) => {
    const res: any = await dispatch(postPerDiemCsvDigest(fileData));
    const code = res?.payload?.code;
    if (code === 201) {
      await uploadFileToS3(fileData);
      dispatch(fetchAttachments);
    }
  };

  return (
    <div className={baseClassName}>
      <h1>Actual Per Diem Csv Digest</h1>
      <PageActionBar className={`${baseClassName}__file-upload`}>
        <FileUpload async accept="text/csv" onFilesAccepted={handleSubmit} isLoading={digestLoading} />
      </PageActionBar>
      {!isEmpty(digestErrors) && <ErrorMessage {...digestErrors} />}
      <CsvList perDiemCsvs={perDiemCsvs} />
    </div>
  );
}

export default PerDiemPage;
