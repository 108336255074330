import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SystemToast from '../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../components/Toaster';
import { getAuthToken } from '../../../../../../utils/auth';
import { handleCustomError } from '../../../../../../utils/errors';
import request from '../../../../../../utils/request';
import { api } from '../../../../../../utils/url';
import { Leg } from './model';

export const fetchLegsByOrderUUIDs = createAsyncThunk(
  'legs/fetchLegsByOrderUUIDs',
  async ({ orderUUIDs }: { orderUUIDs: string[] }) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      }
    };

    const url = api(`/orders/legs?orderUUIDs=${orderUUIDs.join(',')}`);

    try {
      const legsByOrderUUIDs = (await request(url, options)) as Record<string, Leg[]>;
      return legsByOrderUUIDs;
    } catch (err) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to fetch legs')} />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export const fetchLegsByOrderUUID = createAsyncThunk(
  'legs/fetchLegsByOrderUUID',
  async ({ orderUUID }: { orderUUID: string }) => {
    const authHeader = getAuthToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      }
    };

    const url = api(`/orders/legs?orderUUIDs=${orderUUID}`);

    try {
      const legsByOrderUUIDs = (await request(url, options)) as Record<string, Leg[]>;
      return legsByOrderUUIDs;
    } catch (err) {
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to fetch legs')} />,
        {
          position: TOAST_POSITION.BOTTOM_LEFT
        }
      );
    }
  }
);

export interface LegsSliceState {
  legsByUUID: Record<string, Leg>;
  legsByOrderUUID: Record<string, Leg[]>;
  legsToOrder: Record<string, { orderUUID: string; position: number }>;
  loadingLegsByOrderUUID: boolean;
}

const initialState: LegsSliceState = {
  legsByUUID: {},
  legsByOrderUUID: {},
  legsToOrder: {},
  loadingLegsByOrderUUID: false
};

const legsSlice = createSlice({
  name: 'dispatch/legs',
  initialState,
  reducers: {
    updateLegVisibility: (state, action) => {
      state.legsByUUID[action.payload.legUUID].visibility = action.payload.visibility;
      const legOrderInfo = state.legsToOrder[action.payload.legUUID];
      state.legsByOrderUUID[legOrderInfo.orderUUID][legOrderInfo.position].visibility = action.payload.visibility;
    },
    unlinkGraypoolEmpty: (state, action) => {
      const { grayPoolEmptyId, orderUUID } = action.payload;
      const foundLeg = state.legsByOrderUUID[orderUUID].find((leg) => leg.legGroup?.groupUUID === grayPoolEmptyId);
      if (foundLeg && foundLeg.legGroup) {
        foundLeg.legGroup.groupUUID = null;
        state.legsByUUID[foundLeg.uuid].legGroup!.groupUUID = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLegsByOrderUUIDs.pending, (state) => {
      state.loadingLegsByOrderUUID = true;
    });
    builder.addCase(fetchLegsByOrderUUIDs.rejected, (state) => {
      state.loadingLegsByOrderUUID = false;
    });
    builder.addCase(fetchLegsByOrderUUIDs.fulfilled, (state, action) => {
      const data = action.payload || {};

      const legsByOrderUUID = { ...state.legsByOrderUUID };
      const legsToOrder = { ...state.legsToOrder };
      const legsByUUID = { ...state.legsByUUID };
      Object.keys(data).forEach((orderUUID) => {
        const legs = data[orderUUID];
        legs.forEach((leg, index) => {
          legsByUUID[leg.uuid] = leg;
          legsToOrder[leg.uuid] = {
            orderUUID,
            position: index
          };
        });
        legsByOrderUUID[orderUUID] = legs;
      });

      state.legsToOrder = legsToOrder;
      state.legsByUUID = legsByUUID;
      state.legsByOrderUUID = legsByOrderUUID;
      state.loadingLegsByOrderUUID = false;
    });
    builder.addCase(fetchLegsByOrderUUID.fulfilled, (state, action) => {
      const data = action.payload || {};

      // Since we're fetching a single order, delete any previous info on this order & it's legs from state
      const orderUUID = Object.keys(data)[0];
      if (state.legsByOrderUUID[orderUUID]) {
        const oldOrderLegs = state.legsByOrderUUID[orderUUID];
        const oldOrderLegUUIDs = oldOrderLegs.map((l) => l.uuid);
        const legUUIDs = data[orderUUID].map((l) => l.uuid);
        const missingLegsUUIDs = oldOrderLegUUIDs.filter((oldLegUUID) => !legUUIDs.includes(oldLegUUID));
        missingLegsUUIDs.forEach((legUUID) => {
          delete state.legsByUUID[legUUID];
          delete state.legsToOrder[legUUID];
        });
      }

      const newLegs = data[orderUUID];

      if (!newLegs) {
        return;
      }

      newLegs.forEach((leg, index) => {
        state.legsByUUID[leg.uuid] = leg;
        state.legsToOrder[leg.uuid] = {
          orderUUID,
          position: index
        };
      });
      state.legsByOrderUUID[orderUUID] = newLegs;
    });
  }
});

const selectLegsSlice = (state: any): LegsSliceState => state.dispatch.legs as LegsSliceState;

export const selectLegsByUUID = createSelector([selectLegsSlice], (data) => data.legsByUUID);

export const selectLoadingLegsByOrderUUID = createSelector([selectLegsSlice], (data) => data.loadingLegsByOrderUUID);

export const selectLegsByOrderUUID = createSelector([selectLegsSlice], (data) => data.legsByOrderUUID);

export const selectRequireManagerApprovalOnOrderCancellation = (orderUuid: string) =>
  createSelector([selectLegsByOrderUUID], (data) => {
    const legs = data[orderUuid] || [];
    return legs.some((leg) => leg.status === 'driver_paid');
  });

export const selectRequireManagerApprovalOnLegDeletion = (legUuid: string) =>
  createSelector([selectLegsByUUID], (data) => {
    const leg = data[legUuid] || {};
    return leg.status === 'driver_paid';
  });

export const { updateLegVisibility, unlinkGraypoolEmpty } = legsSlice.actions;
export default legsSlice;
