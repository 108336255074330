import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEDI204MessagesByShipper } from '../../store/ediOrdersSlice';
import SectionRow from './components/SectionRow';
import SectionRowHeader from './components/SectionRowHeader';

import './style.css';

interface ShipperSectionProps {
  shipperName: string;
}

function ShipperSection(props: ShipperSectionProps) {
  const baseClassName = 'ShipperSection';

  const [isCollapsed, setIsCollapsed] = useState(false);

  const edi204MessagesByShipper = useSelector(selectEDI204MessagesByShipper);

  const messages = edi204MessagesByShipper[props.shipperName];

  const rows = messages.map((message) => <SectionRow key={message.transactionId} message={message} />);

  let content: any = (
    <div className={`${baseClassName}-rows-container`}>
      <SectionRowHeader />
      {rows}
    </div>
  );

  if (rows.length === 0) {
    content = <div className={`${baseClassName}-rows-container`}>No messages found</div>;
  }

  if (isCollapsed) {
    content = null;
  }

  return (
    <div key={props.shipperName} className={baseClassName}>
      <div className={`${baseClassName}__shipper-name`} onClick={() => setIsCollapsed(!isCollapsed)}>
        {props.shipperName}
      </div>
      {content}
    </div>
  );
}

export default ShipperSection;
