import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

const baseName = 'dispatch/legTotalPriceOverrides';

export interface LegTotalPriceOverrides {
  legUuid: string;
  totalDrayage: string | null;
  totalFSC: string | null;
  totalAccessorial: string;
  totalPrice: string;
}
export const legTotalPriceOverridesAdapter = createEntityAdapter<LegTotalPriceOverrides>({
  selectId: (item: any) => item.legUuid
});

export const fetchLegTotalPriceOverrides = createAsyncThunk(`${baseName}/fetchAll`, async (payload: string[]) => {
  const uuids = payload.join(',');

  const options = {
    method: 'GET'
  };

  const url = api(`/legs/total-prices?legUuids=${uuids}`);

  try {
    const response = (await request(url, options)) as { data: LegTotalPriceOverrides[] };
    return response.data;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Total Prices" />);
  }
});

const legTotalPriceOverridesSlice = createSlice({
  name: baseName,
  initialState: legTotalPriceOverridesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLegTotalPriceOverrides.fulfilled, (state, action) => {
      const data = action.payload || [];
      legTotalPriceOverridesAdapter.setAll(state, data);
    });
  }
});

export default legTotalPriceOverridesSlice.reducer;

const legTotalPriceOverridesSelector = (state: any) => state.dispatch.legTotalPriceOverrides;

export const { selectById: selectLegTotalPriceOverridesById } =
  legTotalPriceOverridesAdapter.getSelectors(legTotalPriceOverridesSelector);
