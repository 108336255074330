import { raiseToast } from '@client/components/Toaster';
import { getAuthToken } from '@client/utils/auth';
import { handleCustomError } from '@client/utils/errors';
import { capitalizeFirstLetter } from '@client/utils/formatString';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SystemToast from '../../../../../components/SystemToast';
import {
  EDI204AcceptDecline,
  EDI204AnswerCode,
  Edi204PurposeCodes,
  EdiCreateOrderOrderInfo,
  OrderCreationRequestItem
} from './types';

export const ediCreateOrdersSliceId = 'ediCreateOrders';

interface EDIOrderInfoStateSlice {
  orderInfoLoading: boolean;
  orderInfo: EdiCreateOrderOrderInfo | null;
}

interface UpdateKeyWithValueAction {
  key: keyof EdiCreateOrderOrderInfo['orderCreation'];
  value: any;
}

export const EDI_204_PURPOSE_CODES: Edi204PurposeCodes = {
  unknown: 'Unknown',
  '00': 'New Order',
  '01': 'Order Cancellation',
  '04': 'Order Update'
};

export const EDI_204_ANSWER_CODES: Record<EDI204AnswerCode, string> = {
  A: 'Acknowledged',
  E: 'Rejected'
};

export const fetchEdiOrderInfo = createAsyncThunk('ediCreateOrders/fetchOrderInfo', async (transactionId: string) => {
  const authHeader = getAuthToken();

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    }
  };

  const url = api(`/edi/204/${transactionId}`);
  try {
    const result = (await request(url, options)) as Record<'data', EdiCreateOrderOrderInfo>;

    return result.data;
  } catch (error) {
    console.log(error);
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch EDI order info" />);
  }
});

export const createEdiOrder = createAsyncThunk(
  'ediCreateOrders/post',
  async ({
    orderCreation,
    ediShipmentId
  }: {
    orderCreation: EdiCreateOrderOrderInfo['orderCreation'];
    ediShipmentId: string;
  }) => {
    if (!orderCreation.pickupStopAccountUuid) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Missing Pickup Stop Department" />);
      return;
    }
    if (!orderCreation.deliveryStopAccountUuid) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Missing Delivery Stop Department" />);
      return;
    }
    if (!orderCreation.pierpass) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Missing pierpass" />);
      return;
    }

    const orderCreationRequest: OrderCreationRequestItem = {
      billOfLading: orderCreation.billOfLading,
      containerNumber: orderCreation.containerNumber,
      customerAccountUuid: orderCreation.customerDepartment.uuid,
      deliveryStopAccountUuid: orderCreation.deliveryStopAccountUuid,
      deliveryType: orderCreation.dropOrLive,
      ediShipmentId,
      importExport: orderCreation.importExport,
      pickupStopAccountUuid: orderCreation.pickupStopAccountUuid,
      pierpass: orderCreation.pierpass,
      purchaseOrderNumber: orderCreation.purchaseOrderNumber,
      returnStopAccountUuid: orderCreation.returnStopAccountUuid,
      shipArrivalEta: orderCreation.vesselEta,
      size: orderCreation.size,
      steamShippingLineAccountUuid: orderCreation.steamShippingLineAccountUuid,
      transportOrderNumber: orderCreation.transportOrderNumber,
      vesselName: orderCreation.vesselName,
      weight: orderCreation.weight,
      zendeskTicketId: orderCreation.zendeskTicketId
    };

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      },
      body: JSON.stringify(orderCreationRequest)
    };

    const url = api(`/orders/create/sync`);
    try {
      const result = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Order Successfully Created" />);

      return result;
    } catch (error) {
      console.log(error);
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(error, 'Unable to fetch EDI order info')}
        />
      );
    }
  }
);

export const send990Message = createAsyncThunk(
  'ediCreateOrders/send990Message',
  async ({ transactionId, answer }: { transactionId: string; answer: EDI204AcceptDecline }) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      },
      body: JSON.stringify({
        edi204TransactionId: transactionId,
        answer
      })
    };

    const url = api(`/edi/990/respondTo204`);
    try {
      const result = await request(url, options);

      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="EDI request sent successfully" />);

      return result.data;
    } catch (error) {
      console.log(error);
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(
            error,
            `Unable to ${capitalizeFirstLetter(answer.toLocaleLowerCase())} EDI message`
          )}
        />
      );
    }
  }
);

const initialState: EDIOrderInfoStateSlice = {
  orderInfo: null,
  orderInfoLoading: false
};

const ediCreateOrdersSlice = createSlice({
  name: 'ediCreateOrders',
  initialState,
  reducers: {
    updateKeyWithValue: (state, action: PayloadAction<UpdateKeyWithValueAction>) => {
      if (!state.orderInfo) {
        return;
      }

      (state.orderInfo.orderCreation[action.payload.key] as any) = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEdiOrderInfo.fulfilled, (state, action) => {
      const data = action.payload || null;

      state.orderInfo = data;
      state.orderInfoLoading = false;
    });
    builder.addCase(fetchEdiOrderInfo.pending, (state) => {
      state.orderInfoLoading = true;
    });
    builder.addCase(fetchEdiOrderInfo.rejected, (state) => {
      state.orderInfo = null;
      state.orderInfoLoading = false;
    });

    builder.addCase(createEdiOrder.fulfilled, (state, action) => {
      if (state.orderInfo) {
        state.orderInfo.orderCreated = action.payload;
      }
    });
    builder.addCase(createEdiOrder.pending, () => {});
    builder.addCase(createEdiOrder.rejected, () => {});

    builder.addCase(send990Message.fulfilled, (state, action) => {
      if (state.orderInfo) {
        const actionAnswer = action.meta.arg.answer;
        state.orderInfo.matching990.sentToOrderful = true;
        state.orderInfo.matching990.answerCode = actionAnswer === 'ACCEPT' ? 'A' : 'E';
      }
    });
    builder.addCase(send990Message.pending, () => {});
    builder.addCase(send990Message.rejected, () => {});
  }
});

const selectEdiCreateOrdersSlice = (state: any): EDIOrderInfoStateSlice => state.ediCreateOrders as EDIOrderInfoStateSlice;

export const selectEDIOrderInfoLoading = createSelector([selectEdiCreateOrdersSlice], (data) => data.orderInfoLoading);

export const selectEDIOrderInfo = createSelector([selectEdiCreateOrdersSlice], (data) => data.orderInfo);

export const selectEDI204Purpose = createSelector([selectEdiCreateOrdersSlice], (data) => {
  if (!data.orderInfo) {
    return EDI_204_PURPOSE_CODES.unknown;
  }

  return EDI_204_PURPOSE_CODES[data.orderInfo.original.otherData.purpose] || EDI_204_PURPOSE_CODES.unknown;
});

export const { updateKeyWithValue } = ediCreateOrdersSlice.actions;

export default ediCreateOrdersSlice.reducer;
