import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { raiseToast } from '../../../../../components/Toaster';
import SystemToast from '../../../../../components/SystemToast';

const baseName = 'dispatch/orderMilestones';

export interface OrderMilestone {
  orderUuid: string;
  orderReceivedAt: Date;
}

export interface PatchOrderMilestoneRequest {
  orderUuid?: string;
  orderReceivedAt?: Date;
}

export const orderMilestonesAdapter = createEntityAdapter<OrderMilestone>({
  selectId: (orderMilestone) => orderMilestone.orderUuid
});

export const fetchOrderMilestones = createAsyncThunk(`${baseName}/fetch`, async (orderUuid: string) => {
  const options = {
    method: 'GET'
  };

  const url = api(`/orders/${orderUuid}/order-milestones`);

  try {
    const response = await request(url, options);
    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch order milestone" />);
    throw err;
  }
});

export const updateOrderMilestone = createAsyncThunk(`${baseName}/update`, async (arg: OrderMilestone) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(arg)
  };

  const url = api(`/orders/order-milestones`);

  try {
    const response = await request(url, options);
    return response;
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to update order milestone" />);
  }
});

export const orderMilestonesSlice = createSlice({
  name: baseName,
  initialState: orderMilestonesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrderMilestones.fulfilled, (state, action) => {
      const data = action.payload || null;
      orderMilestonesAdapter.setOne(state, data);
    });
    builder.addCase(updateOrderMilestone.fulfilled, (state, action) => {
      const data = action.meta.arg || null;
      orderMilestonesAdapter.setOne(state, data);
    });
  }
});

export default orderMilestonesSlice.reducer;

const orderMilestonesSelector = (state: any) => state.dispatch.orderMilestones;

export const { selectById: selectOrderMilestonesById } = orderMilestonesAdapter.getSelectors(orderMilestonesSelector);
