// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorMessage {
  width: 65vw;
  max-width: 1280px;
  margin: 1px 0 5px;
}

  .errorMessage__text {
    font-size: 1.12rem;
    font-weight: 500;
    color: var(--color-red-4);
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/ArAp/features/PerDiemDigest/Components/ErrorMessage/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,iBAAiB;AAOnB;;EALE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;EAC3B","sourcesContent":[".errorMessage {\n  width: 65vw;\n  max-width: 1280px;\n  margin: 1px 0 5px;\n\n  &__text {\n    font-size: 1.12rem;\n    font-weight: 500;\n    color: var(--color-red-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
